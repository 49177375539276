import React, { useEffect, useState } from 'react'
import styles from './subject.module.css'
import { Action, Active, Inactive, Left, Right, RightArrow, Search, Status } from '../../svg'
import { formselect, selectInput } from '../MaterialUI';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import EditSubject from './EditSubject';
import { custom, save } from '../MaterialUI';
import { MenuButton, Dropdown, Menu, MenuItem as MenuAction } from '@mui/joy'
import { Formik, useFormik } from 'formik';
import Api from '../../helper/api';
import * as yup from "yup";
import Toastify from '../../helper/Toastify';
import Delete from '../../shared/Delete/Delete';


const Subjects = () => {
  const toast = new Toastify();
  const api = new Api();

  // states-------------
  const [open, setOpen] = useState(false);
  const [openDlt, setOpenDlt] = useState(false);
  const [statuss, setStatus] = useState("");
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(50)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0);
  const [subjectEdit, setSubjectedit] = useState({
    name: '', code: '', status: ''
  })
  const [subjectToDelete, setSubjectToDelete] = useState("")
  const [subjects, setSubjects] = useState([]);
  const [refresh, setRefresh] = useState(false)

  // calculate start & end of items -------------------
  const start = (page - 1) * limit + 1;
  const end =
    totalPages === page
      ? totalItems
      : (page - 1) * limit + limit;


  // increment the page------------------------
  const increment = () => {
    if (page < totalPages) {
      setPage((prev) => prev + 1)
    }
  }

  // decrement the page------------------------
  const decrement = () => {
    if (page > 1) {
      setPage((prev) => prev - 1)
    }
  }

  // open the edit popup----------------------
  const handleOpen = (subject) => {
    setOpen(true)
    setSubjectedit(subject)
  };


  // close the edit popup---------------------
  const handleClose = () => setOpen(false);

  // fetch all subject -------------------------
  const AllSubject = async () => {
    try {
      const { data, status } = await api.subject({}, `?search=${search}&page=${page}&status=${statuss}&limit=${limit}`);
      if (status === 200) {
        setSubjects(data.data)
        setTotalPages(data.totalPages)
        setTotalItems(data.totalItems)
      }
    } catch (err) {
      console.log(err)
    }
  }

  // schema --------------------
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    code: yup.string().required("Code is required"),
    status: yup.string().required("Status is required")
  })


  // formik to handle forms data --------------------
  const {
    errors,
    values,
    handleChange,
    touched,
    handleBlur,
    handleSubmit,
    resetForm
  } = useFormik({
    initialValues: {
      name: "",
      code: "",
      status: ''
    },
    validationSchema: schema,
    onSubmit: () => {
      AddSubject();
    }
  })

  // add subject -----------------------
  const AddSubject = async () => {
    try {
      const { data, status } = await api.addSubject(values)
      if (status === 200) {
        setRefresh(!refresh)
        resetForm();
        toast.success("Saved Successfully", "Your changes have been saved successfully")
      }
    } catch (err) {
      if (err.response) {
        toast.error("Error occured", err.response.data.message)
      }
    }
  }

  // run on initial render-------------------
  useEffect(() => {
    AllSubject();
  }, [refresh, page, search, statuss, limit])


  // delete subject ------------------------
  const handleDelete = async () => {
    try {
      const { data, status } = await api.trashSubject("", { id: subjectToDelete })
      if (status === 200) {
        setRefresh(!refresh)
        setOpenDlt(false)
        setSubjectToDelete("")
        toast.success("Deleted Successfully", "Your changes have been saved successfully")
      }
    } catch (err) {
      if (err.response) {
        toast.error("Error occured", err.response.data.message)
      }
    }
  }

  return (
    <div className={styles.container}>
      <EditSubject handleClose={handleClose} open={open} subjectEdit={subjectEdit} refresh={refresh} setRefresh={setRefresh} />
      <Delete handleClose={() => setOpenDlt(false)} open={openDlt} handleDelete={handleDelete} />
      <div className={styles.head}>
        <h1>Master Data</h1>
        <span>
          home <RightArrow /> Master Data <RightArrow /> <span style={{ color: 'var(--Gray-900, #191B1C)' }}>Subjects</span>
        </span>
      </div>
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.subhead}>
            <Select className={styles.selectinput}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={selectInput}
              value={statuss}
              onChange={(e) => setStatus(e.target.value)}
              IconComponent={Status}
              displayEmpty
              defaultValue=''
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="ACTIVE">Active</MenuItem>
              <MenuItem value="INACTIVE">Inactive</MenuItem>
            </Select>
            <div className={styles.search}><Search /> <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search' /></div>
          </div>
          <div className={styles.header}>
            <div className={styles.first}>no.</div>
            <div className={styles.second}>subject name</div>
            <div className={styles.third}>code</div>
            <div className={styles.third}>status</div>
            <div className={`${styles.third} ${styles.flexEnd}`}>action</div>
          </div>
          {
            subjects.map((subject, index) => {
              return (
                <div className={styles.info}>
                  <div className={styles.first}>{(page - 1) * limit + index + 1}</div>
                  <div className={styles.second}>{subject.name}</div>
                  <div className={styles.third} style={{ textTransform: "none" }}>{subject.code}</div>
                  <div className={styles.third}>
                    {
                      subject.status === 'ACTIVE'
                        ? <span className={styles.active}>
                          <Active /> Active
                        </span>
                        : <span className={styles.inactive}>
                          <Inactive /> Inactive
                        </span>
                    }
                  </div>
                  <div className={`${styles.third} ${styles.flexEnd}`}><Dropdown>
                    <MenuButton style={{ border: 'none', padding: '0' }}><Action /></MenuButton>
                    <Menu>
                      <MenuAction onClick={() => handleOpen(subject)}>Edit</MenuAction>
                      <MenuAction onClick={() => {
                        setOpenDlt(true)
                        setSubjectToDelete(subject._id)
                      }}>Delete</MenuAction>
                    </Menu>
                  </Dropdown></div>
                </div>
              )
            })
          }
          <div className={styles.pagination}>
            <div>
              Rows per page:
              <Dropdown>
                <MenuButton style={{ border: 'none', padding: '0' }}>
                  <div>
                    {limit}
                  </div>
                </MenuButton>
                <Menu>
                  <MenuAction onClick={() => setLimit(15)} >15</MenuAction>
                  <MenuAction onClick={() => setLimit(25)} >25</MenuAction>
                  <MenuAction onClick={() => setLimit(50)} >50</MenuAction>
                  <MenuAction onClick={() => setLimit(75)} >75</MenuAction>
                  <MenuAction onClick={() => setLimit(100)} >100</MenuAction>
                </Menu>
              </Dropdown>
            </div>
            <div>Showing {start} to {end} of {totalItems} entries</div>
            <div>
              <Right handleClick={decrement} />
              {/* <p>01</p> */}
              <p className={styles.onPage}>{page}</p>
              <Left handleClick={increment} />
            </div>
          </div>
        </div>

        <div className={styles.right}>
          <h5>Add New Subject</h5>

          <label>Subject Name</label>
          <br />
          <div className={styles.input}>
            <input type="text" name="name" onBlur={handleBlur} value={values.name} onChange={handleChange} placeholder='Enter' />
          </div>
          {
            errors.name && touched.name && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-14px" }}>{errors.name}</p>
          }

          <label>Code</label>
          <br />
          <div className={styles.input}>
            <input type="string" name="code" onBlur={handleBlur} value={values.code} onChange={handleChange} placeholder='Enter' />
          </div>
          {
            errors.code && touched.code && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-14px" }}>{errors.code}</p>
          }

          <label>Status</label>
          <br />
          <Select className={styles.formselect}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={formselect}
            IconComponent={Status}
            displayEmpty
            defaultValue=''
            name='status' value={values.status}
            onChange={handleChange}
          >
            <MenuItem value="">status</MenuItem>
            <MenuItem value="ACTIVE">Active</MenuItem>
            <MenuItem value="INACTIVE">Inactive</MenuItem>
          </Select>
          {
            errors.status && touched.status && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-5px" }}>{errors.status}</p>
          }
          <div className={styles.buttons}>
            <Button sx={custom} disableElevation variant="contained" onClick={() => resetForm()}>Cancel</Button>
            <Button sx={save} disableElevation onClick={handleSubmit} variant="contained">save</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Subjects
