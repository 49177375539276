import React, { useEffect, useState } from 'react'
import styles from '../subjects/subject.module.css'
import { Action, Active, Inactive, Left, Right, RightArrow, Search, Status } from '../../svg'
import { formselect, selectInput } from '../MaterialUI';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { custom, save } from '../MaterialUI';
import EditTopic from './EditTopic';
import AddTopic from './AddTopic';
import { MenuButton, Dropdown, Menu, MenuItem as MenuAction } from '@mui/joy'
import Api from '../../helper/api';
import Delete from '../../shared/Delete/Delete';
import Toastify from '../../helper/Toastify';

const TrashedTopics = () => {
    const api = new Api();
    const toast = new Toastify();

    // states-------------
    const [openDlt, setOpenDlt] = useState(false);
    const [topicToDelete, setTopicToDelete] = useState("")
    const [statuss, setStatus] = useState("");
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const [totalPages, setTotalPages] = useState(1)
    const [totalItems, setTotalItems] = useState(0);
    const [topics, setTopics] = useState([]);
    const [refresh, setRefresh] = useState(false);

    // calculate start & end of items -------------------
    const start = (page - 1) * limit + 1;
    const end =
        totalPages === page
            ? totalItems
            : (page - 1) * limit + limit;

    // increment the page------------------------
    const increment = () => {
        if (page < totalPages) {
            setPage((prev) => prev + 1)
        }
    }

    // decrement the page------------------------
    const decrement = () => {
        if (page > 1) {
            setPage((prev) => prev - 1)
        }
    }


    // fetch all topics -------------------------
    const AllTopic = async () => {
        try {
            const { data, status } = await api.allTrashedTopic({}, `?search=${search}&page=${page}&status=${statuss}&limit=${limit}`);
            if (status === 200) {
                setTopics(data.data)
                setTotalPages(data.totalPages)
                setTotalItems(data.totalItems)
            }
        } catch (err) {
            console.log(err)
        }
    }


    // run on initial render-------------------
    useEffect(() => {
        AllTopic();
    }, [refresh, page, search, statuss, limit])


    // delete topic ------------------------
    const handleDelete = async () => {
        try {
            const { data, status } = await api.deleteTopic("", { id: topicToDelete })
            if (status === 200) {
                setRefresh(!refresh)
                setOpenDlt(false)
                setTopicToDelete("")
                toast.success("Deleted Successfully", "Your changes have been saved successfully")
            }
        } catch (err) {
            if (err.response) {
                toast.error("Error occured", err.response.data.message)
            }
        }
    }

    const handleRestore = async (id) => {
        try {
            const { data, status } = await api.restoreTopic("", { id: id })
            if (status === 200) {
                setRefresh(!refresh)
                setOpenDlt(false)
                setTopicToDelete("")
                toast.success("Restored Successfully", "Your changes have been saved successfully")
            }
        } catch (err) {
            if (err.response) {
                toast.error("Error occured", err.response.data.message)
            }
        }
    }

    return (
        <div className={styles.container}>
            <Delete handleClose={() => setOpenDlt(false)} open={openDlt} handleDelete={handleDelete} />
            <div className={styles.head}>
                <h1>Master Data</h1>
                <span>
                    home <RightArrow /> Master Data <RightArrow /> <span style={{ color: 'var(--Gray-900, #191B1C)' }}>Topics</span>
                </span>
            </div>
            <div className={styles.main}>
                <div className={styles.left} style={{ width: '100%' }}>
                    <div className={styles.subhead}>
                        <Select className={styles.selectinput}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            sx={selectInput}
                            value={statuss}
                            onChange={(e) => setStatus(e.target.value)}
                            IconComponent={Status}
                            displayEmpty
                            defaultValue=''
                        >
                            <MenuItem value="">All </MenuItem>
                            <MenuItem value="ACTIVE">Active</MenuItem>
                            <MenuItem value="INACTIVE">Inactive</MenuItem>
                        </Select>
                        <div className={styles.search}><Search /> <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search' /></div>
                    </div>
                    <div className={styles.header}>
                        <div className={styles.fourth}>no.</div>
                        <div className={styles.third}>subject name</div>
                        <div className={styles.third}>chapter name</div>
                        <div className={styles.third}>topic name</div>
                        <div className={styles.third}>status</div>
                        <div className={`${styles.third} ${styles.flexEnd}`}>action</div>
                    </div>
                    {
                        topics.map((topic, index) => (
                            <div className={styles.info} key={topic._id}>
                                <div className={styles.fourth}>{(page - 1) * 10 + index + 1}</div>
                                <div className={styles.third}> {topic.subject.name}</div>
                                <div className={styles.third}>{topic.chapter.name}</div>
                                <div className={styles.third}>{topic.name}</div>
                                <div className={styles.third}>
                                    {
                                        topic.status === 'ACTIVE'
                                            ? <span className={styles.active}>
                                                <Active /> Active
                                            </span>
                                            : <span className={styles.inactive}>
                                                <Inactive /> Inactive
                                            </span>
                                    }
                                </div>
                                <div className={`${styles.third} ${styles.flexEnd}`}>
                                    <Dropdown>
                                        <MenuButton style={{ border: 'none', padding: '0' }}><Action /></MenuButton>
                                        <Menu>
                                            <MenuAction onClick={() => handleRestore(topic._id)}>Restore</MenuAction>
                                            <MenuAction onClick={() => {
                                                setOpenDlt(true)
                                                setTopicToDelete(topic._id)
                                            }}>Delete</MenuAction>
                                        </Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        ))
                    }
                    <div className={styles.pagination}>
                        <div>
                            Rows per page:
                            <Dropdown>
                                <MenuButton style={{ border: 'none', padding: '0' }}>
                                    <div>
                                        {limit}
                                    </div>
                                </MenuButton>
                                <Menu>
                                    <MenuAction onClick={() => setLimit(15)} >15</MenuAction>
                                    <MenuAction onClick={() => setLimit(25)} >25</MenuAction>
                                    <MenuAction onClick={() => setLimit(50)} >50</MenuAction>
                                    <MenuAction onClick={() => setLimit(75)} >75</MenuAction>
                                    <MenuAction onClick={() => setLimit(100)} >100</MenuAction>
                                </Menu>
                            </Dropdown>
                        </div>
                        <div>Showing {start} to {end} of {totalItems} entries</div>
                        <div>
                            <Right handleClick={decrement} />
                            {/* <p>01</p> */}
                            <p className={styles.onPage}>{page}</p>
                            <Left handleClick={increment} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrashedTopics
