import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./shared/layout/Layout";
import Login from "./pages/login/Login";
import Subjects from "./pages/subjects/Subjects";
import Quiz from "./pages/quiz/Quiz";
import Chapters from "./pages/chapter/Chapters";
import Topics from "./pages/topics/Topics";
import Levels from "./pages/Levels/Levels";
import AddQA from "./pages/quiz/AddQA";
import AllTest from "./pages/alltest/AllTest";
import AddTest from "./pages/alltest/AddTest";
import Students from "./pages/students/Students";
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './global.css'
import EditTest from "./pages/alltest/EditTest";
import EditQuiz from "./pages/quiz/EditQuiz";
import ViewQuiz from "./pages/quiz/ViewQuiz";
import ViewTest from "./pages/alltest/ViewTest";
import Subscribers from "./pages/Subscribers/Subscribers";
import TrashLevels from "./pages/Levels/TrashLevels";
import TrashedTopics from "./pages/topics/TrashedTopics";
import TrashChapters from "./pages/chapter/TrashChapters";
import TrashSubjects from "./pages/subjects/TrashSubject";

function App() {

  return (
    <BrowserRouter>
      <Layout>
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          theme="dark"
        >
        </ToastContainer>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/master/subjects" element={<Subjects />} />
          <Route path="master/chapters" element={<Chapters />} />
          <Route path="master/topics" element={<Topics />} />
          <Route path="master/levels" element={<Levels />} />
          <Route path="/trash/subjects" element={<TrashSubjects />} />
          <Route path="trash/chapters" element={<TrashChapters />} />
          <Route path="trash/topics" element={<TrashedTopics />} />
          <Route path="trash/levels" element={<TrashLevels />} />
          <Route path="qa/quiz" element={<Quiz />} />
          <Route path='qa/quiz/add-qa' element={<AddQA />} />
          <Route path='qa/quiz/edit-qa/:id' element={<EditQuiz />} />
          <Route path='qa/quiz/view-qa/:id' element={<ViewQuiz />} />
          <Route path="alltest/all-test" element={<AllTest />} />
          <Route path="alltest/all-test/add-test" element={<AddTest />} />
          <Route path="alltest/all-test/edit-test/:id" element={<EditTest />} />
          <Route path="alltest/all-test/view-test/:id" element={<ViewTest />} />
          <Route path="users/students" element={<Students />} />
          <Route path="users/subscribers" element={<Subscribers />} />
        </Routes>
      </Layout>
    </BrowserRouter >
  );
}

export default App;
