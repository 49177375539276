import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styles from '../subjects/subject.module.css'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { custom, formselect, save } from '../MaterialUI';
import { Exit, Status } from '../../svg';

import Selects from 'react-select'
import Api from '../../helper/api';
import * as yup from "yup";
import Toastify from '../../helper/Toastify';
import { useFormik } from 'formik';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "none",
  padding: "27px 22px",
  height: "fit-content",
  display: "block",
  width: '380px',
  "&:focus": {
    outline: "none",
  },
};

const EditTopic = ({ handleClose, topicToEdit, open, subjects, chapters, refresh, setRefresh }) => {
  const toast = new Toastify();
  const api = new Api();

  // states
  const [sub, setSub] = useState("");
  const [chapt, setChapt] = useState("");


  // schema --------------------
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    code: yup.string().required("Code is required"),
    status: yup.string().required("Status is required"),
    subject: yup.string().required("Subject is required"),
    chapter: yup.string().required("Chapter is required")
  })

  // formik to handle forms data --------------------
  const {
    errors,
    values,
    handleChange,
    touched,
    handleBlur,
    handleSubmit,
    setValues,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      name: "",
      code: "",
      status: '',
      subject: "",
      chapter: ""
    },
    validationSchema: schema,
    onSubmit: () => {
      updateTopic();
    }
  })

  useEffect(() => {
    setValues({
      ...topicToEdit,
      subject: topicToEdit?.subject?._id,
      chapter: topicToEdit?.chapter?._id
    })
    setChapt({
      label: topicToEdit?.chapter?.name,
      value: topicToEdit?.chapter?._id,
    })
    setSub({
      label: topicToEdit?.subject?.name,
      value: topicToEdit?.subject?._id,
    })
  }, [topicToEdit, open])

  // add subject -----------------------
  const updateTopic = async () => {
    try {
      const { data, status } = await api.updateTopic(values, {
        id: topicToEdit._id
      })
      if (status === 200) {
        setRefresh(!refresh)
        resetForm();
        setSub("");
        setChapt("");
        handleClose()
        toast.success("Updated Successfully", "Your changes have been updated successfully")
      }
    } catch (err) {
      if (err.response) {
        toast.error("Error occured", err.response.data.message)
      }
    }
  }


  const close = () => {
    handleClose();
    resetForm();
    setSub("");
    setChapt("");
  }

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.editHead}>
          <h2 className={styles.update}>Edit Topic</h2>
          <Exit handleClose={close} />
        </div>
        <form className={styles.form}>
          <label>Subject Name*</label>
          <br />
          <div style={{ marginBottom: "21px", marginTop: "6px" }}>
            <Selects
              options={subjects.map((subject) => {
                return {
                  value: subject._id,
                  label: subject.name
                }
              })}
              onChange={(e) => {
                setSub(e)
                setFieldValue("subject", e.value)
              }}
              name='subject'
              value={sub}
              styles={{
                input: (styles) => ({ ...styles, fontSize: "14px", color: "#8C94A3 !important" }),
                container: (styles) => ({ ...styles, width: "100%" }),
                control: () => ({ display: "flex", border: "1px solid #E9EAF0", borderRadius: "4px", outline: "none", padding: 3, color: "#8C94A3 !important" }),
                placeholder: (styles) => ({ ...styles, fontSize: 14, color: "#8C94A3" }),

              }}
            />
          </div>
          {
            errors.subject && touched.subject && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-14px" }}>{errors.subject}</p>
          }
          <label>Chapter Name*</label>
          <br />
          <div style={{ marginBottom: "21px", marginTop: "6px" }}>
            <Selects
              options={chapters.filter((chapter) => chapter.subject._id.includes(values.subject)).map((chapter) => {
                return {
                  value: chapter._id,
                  label: chapter.name
                }
              })}
              onChange={(e) => {
                setChapt(e)
                setFieldValue("chapter", e.value)
              }}
              name='chapter'
              value={chapt}
              styles={{
                input: (styles) => ({ ...styles, fontSize: "14px", color: "#8C94A3 !important" }),
                container: (styles) => ({ ...styles, width: "100%" }),
                control: () => ({ display: "flex", border: "1px solid #E9EAF0", borderRadius: "4px", outline: "none", padding: 3, color: "#8C94A3 !important" }),
                placeholder: (styles) => ({ ...styles, fontSize: 14, color: "#8C94A3" }),

              }}
            />
          </div>
          {
            errors.chapter && touched.chapter && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-14px" }}>{errors.chapter}</p>
          }

          <label>Topic Name*</label>
          <br />
          <div className={styles.input}>
            <input type="text" placeholder='Enter' name='name' value={values.name} onChange={handleChange} onBlur={handleBlur} />
          </div>
          {
            errors.name && touched.name && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-14px" }}>{errors.name}</p>
          }

          <label>Code*</label>
          <br />
          <div className={styles.input}>
            <input type="text" name='code' value={values.code} onChange={handleChange} onBlur={handleBlur} placeholder='Enter' />
          </div>
          {
            errors.code && touched.code && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-14px" }}>{errors.code}</p>
          }
          <label>Status*</label>
          <br />
          <Select className={styles.formselect}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={formselect}
            onChange={handleChange}
            onBlur={handleBlur}
            name='status'
            value={values.status}
            IconComponent={Status}
            displayEmpty
            defaultValue=''
          >
            <MenuItem value="">status</MenuItem>
            <MenuItem value="ACTIVE">Active</MenuItem>
            <MenuItem value="INACTIVE">Inactive</MenuItem>
          </Select>
          {
            errors.status && touched.status && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-5px" }}>{errors.status}</p>
          }
          <div className={styles.buttons}>
            <Button sx={custom} disableElevation onClick={close} variant="contained">Cancel</Button>
            <Button sx={save} onClick={handleSubmit} disableElevation variant="contained">Update</Button>
          </div>
        </form>
      </Box>
    </Modal>
  )
}

export default EditTopic
