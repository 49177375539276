import React, { useEffect, useMemo, useState } from 'react'
import styles from '../quiz/quiz.module.css'
import { Action, AnswerKey, Darkmenu, DeleteIcon, EditIcon, Info, LightDelete, Lightedit, Menu, Next, Opened, Plusicon, RightArrow, Search, Status, Table } from '../../svg'
import { Button, CircularProgress, MenuItem, Select } from '@mui/material'
import { custom, customradius, formselect, save, saveradius, selectInput } from '../MaterialUI'
import { useNavigate } from 'react-router-dom'
import Api from '../../helper/api'
import Toastify from '../../helper/Toastify'

const Preview = ({ id, course, handleCancel, showBtn, setTitle }) => {
    const navigate = useNavigate();
    const api = new Api();
    const toast = new Toastify();

    // states --------------------------
    // ---------------------------------
    const [opened, setOpened] = useState(null)
    const [loading, setLoading] = useState(false)
    const [details, setDetails] = useState({ questions: [], chapter: "" });

    // run useEffect and save all the fields here
    useEffect(() => {
        id && getTest();
    }, [id])

    const getTest = async () => {
        try {
            const { data, status } = await api.getTestById("", { id })

            if (status === 200) {
                setDetails(data.data)
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleOpenAnswer = (id) => {
        const exist = opened === id;
        if (exist) {
            setOpened(null)
        } else {
            setOpened(id)
        }
    }


    // publish -----------------------
    const publish = async () => {
        try {
            setLoading(true)
            const { data, status } = await api.updateTest({ state: 3 }, { id })
            if (status === 200) {
                toast.success("Published Successfully", "Your changes have been published successfully")
                setLoading(false)
                setTimeout(() => {
                    navigate("/alltest/all-test")
                }, 1500);
            }
        } catch (err) {
            if (err.response) {
                toast.error("Error occured", err.response.data.message)
            }
            setLoading(false)
        }
    }
    return (
        <>
            <div className={styles.headerTitle}>
                <span>preview : </span>
            </div>
            <div style={{ padding: '24px' }}>
                <Button sx={{ width: '100%', height: '52px', fontSize: '24px' }} disableElevation variant="contained">
                    {
                        course.chapters.find((chapter) => chapter._id === details.chapter)?.name
                    }
                </Button>
                <p style={{ textAlign: 'center', color: '#232521', fontWeight: '600' }}>
                    {
                        course.levels.find((level) => level._id === details?.level)?.name
                    }
                    {""} Test</p>

            </div>
            <div className={styles.row} style={{ gridTemplateColumns: '1fr' }}>
                {
                    details.questions.map((ques, i) => {
                        return (
                            <div className={styles.column}>
                                <div className={styles.columnhead}>Question {i + 1}</div>
                                <div className={styles.columncontent}>
                                    <div className="ql-editor">
                                        <div className="reportLayout">
                                            <div dangerouslySetInnerHTML={{ __html: ques?.que?.html }}>
                                            </div>
                                        </div>

                                    </div>
                                    {
                                        ques.que?.img?.length > 0 && <img src={ques.que?.img[0].src} alt="" />
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className={styles.end}>
                <span className={styles.line}></span>end of test <span className={styles.line}></span>
            </div>
            <div>
                <div className={styles.columnhead} onClick={() => handleOpenAnswer(1)} style={{ background: opened === 1 ? '' : '#6A6B6C' }}>answer key {opened === 1 ? <AnswerKey /> : <Opened />}</div>
                {
                    opened === 1 &&
                    <div className={styles.columncontent}>
                        {
                            details.questions.map((ans, i) => (
                                <>
                                    <span style={{ display: 'flex', gap: '39px', marginTop: '33px' }}>
                                        <strong>question {i + 1}:</strong>
                                        <span>
                                            <div className="ql-editor">
                                                <div className="reportLayout">
                                                    <div dangerouslySetInnerHTML={{ __html: ans?.ans?.html }}>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                ans.ans?.img?.length > 0 && <img src={ans.ans?.img[0].src} alt="" />
                                            }
                                        </span>
                                    </span>
                                    <br />
                                </>
                            ))
                        }
                    </div>
                }
                <br />
                <div className={styles.columnhead} onClick={() => handleOpenAnswer(2)} style={{ background: opened === 2 ? '' : '#6A6B6C' }}>solution{opened === 2 ? <AnswerKey /> : <Opened />}</div>
                {
                    opened === 2 &&
                    <div className={styles.columncontent}>
                        {
                            details.questions.map((sol, i) => (
                                <>
                                    <span style={{ display: 'flex', gap: '39px', marginTop: '33px' }}>
                                        <strong>solution {i + 1}:</strong>
                                        <span>
                                            <div className="ql-editor">
                                                <div className="reportLayout">
                                                    <div dangerouslySetInnerHTML={{ __html: sol?.sol?.html }}>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                sol.sol?.img?.length > 0 && <img src={sol.sol?.img[0].src} alt="" />
                                            }
                                        </span>
                                    </span><br />
                                </>
                            ))
                        }

                    </div>
                } <br />
            </div>
            {
                !showBtn &&
                <div className={styles.buttonquiz}>
                    <Button sx={customradius} onClick={() => setTitle(2)} disableElevation variant="contained">Cancel</Button>
                    <Button sx={{ ...saveradius, width: "125px" }} disabled={loading} disableElevation variant="contained" onClick={publish}>
                        {
                            loading
                                ? <CircularProgress size={25} thickness={4.5} sx={{ color: "#fff" }} />
                                : "Publish"
                        }
                    </Button>
                </div>
            }
        </>
    )
}

export default Preview