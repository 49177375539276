import React, { useEffect, useState } from 'react'
import styles from './quiz.module.css'
import { Action, Next, RightArrow, Search, Status } from '../../svg'
import { Button, MenuItem, Select } from '@mui/material'
import { selectInput } from '../MaterialUI'
import { MenuButton, Dropdown, Menu, MenuItem as MenuAction } from '@mui/joy'
import { useNavigate } from 'react-router-dom'
import Selects from 'react-select'
import Delete from '../../shared/Delete/Delete'

import Api from '../../helper/api'
import Toastify from '../../helper/Toastify'


const Quiz = () => {
  const navigate = useNavigate();
  const api = new Api();
  const toast = new Toastify();

  const [allQuiz, setAllQuiz] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [quizToDelete, setQuizToDelete] = useState({
    open: false,
    id: ""
  })
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState("")
  const [statuss, setStatus] = useState("");
  const [search, setSearch] = useState('')
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    fetchAllQuiz();
  }, [selectedSubject, refresh]);

  useEffect(() => {
    AllSubject();
  }, [])

  const fetchAllQuiz = async () => {
    try {
      const { data, status } = await api.getAllQuiz({}, `?search=${search}&status=${statuss}&subject=${selectedSubject.value ? selectedSubject.value : ""}`);

      if (status === 200) {
        setAllQuiz(data.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  // filter topics & levels on user clicks
  const topics = allQuiz.find((quiz) => quiz._id._id === selectedChapter)?.topic
  const levels = topics?.find((topic) => topic.topic._id === selectedTopic)?.level

  // fetch all subject -------------------------
  const AllSubject = async () => {
    try {
      const { data, status } = await api.subject("", "?");
      if (status === 200) {
        setSubjects(data.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleDelete = async () => {
    try {
      const { data, status } = await api.deleteQuiz("", { id: quizToDelete.id })

      if (status === 200) {
        handleClose();
        toast.success("Delete successfully");
        setRefresh(!refresh)
      }
    } catch (err) {
      toast.error(err.response.data.message || "Error deleting quiz")
    }
  }

  const handleClose = () => {
    setQuizToDelete({
      open: false,
      id: ""
    })
  }

  return (
    <div className={styles.container}>
      <Delete handleClose={handleClose} open={quizToDelete.open} handleDelete={handleDelete} />

      <div className={styles.head}>
        <h1>Q&A Management</h1>
        <span>
          home <RightArrow /> Q&A Management <RightArrow /> <span style={{ color: 'var(--Gray-900, #191B1C)' }}>Quiz</span>
        </span>
      </div>
      <div className={styles.subhead}>
        <span style={{ display: 'flex', gap: '15px', paddingLeft: '30px', width: "200px" }}>
          <Selects
            options={[
              { value: "", label: "All" },
              ...subjects.map((subject) => {
                return {
                  value: subject._id,
                  label: subject.name
                }
              })
            ]}
            onChange={(e) => {
              setSelectedSubject(e)
            }}
            name='subject'
            value={selectedSubject}
            styles={{
              input: (styles) => ({ ...styles, fontSize: "14px", color: "#8C94A3 !important" }),
              container: (styles) => ({ ...styles, width: "100%" }),
              control: () => ({ display: "flex", border: "1px solid #E9EAF0", borderRadius: "4px", outline: "none", padding: 3, color: "#8C94A3 !important" }),
              placeholder: (styles) => ({ ...styles, fontSize: 14, color: "#8C94A3" }),
            }}
          />
          {/* <div className={styles.search}><Search /> <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search' /></div> */}
        </span>
        <Button sx={{ marginRight: '30px', textTransform: 'capitalize' }} onClick={() => navigate('add-qa')} variant="contained">Add New Q&A</Button>
      </div>

      <div className={styles.content}>
        <div className={styles.subcontent}>
          <h5>chapter</h5>
          <div className={styles.list}>

            {
              allQuiz.map((quiz) => (
                <div className={styles.name}>
                  <span>{quiz?._id?.name} <span className={styles.num}>{quiz.topic.length}</span> </span>
                  <Next handleClick={() => setSelectedChapter(quiz._id._id)} />
                </div>
              ))
            }
          </div>
        </div>

        <div className={styles.subcontent}>
          <h5>Topics</h5>
          <div className={styles.list}>
            {
              topics?.map((topic) => (
                <div className={styles.name}>
                  <span>{topic?.topic?.name} <span className={styles.num}>{topic.level.length}</span> </span>
                  <Next handleClick={() => setSelectedTopic(topic.topic._id)} />
                </div>
              ))
            }
          </div>
        </div>

        <div className={styles.subcontent}>
          <h5>levels</h5>
          <div className={styles.list}>
            {
              levels?.map((level) => (
                <div className={styles.name}>
                  <span>{level.level.name}
                    {
                      level.status === "ACTIVE"
                        ? <span className={styles.green}>Active</span>
                        : <span className={styles.red}>Inactive</span>
                    }
                  </span>
                  <Dropdown>
                    <MenuButton style={{ border: 'none', padding: '0' }}><Action /></MenuButton>
                    <Menu>
                      <MenuAction onClick={() => navigate(`/qa/quiz/view-qa/${level.quizId}`)}>View</MenuAction>
                      <MenuAction onClick={() => navigate(`/qa/quiz/edit-qa/${level.quizId}`)}>Edit</MenuAction>
                      <MenuAction onClick={() => setQuizToDelete({ open: true, id: level.quizId })}>Delete</MenuAction>
                    </Menu>
                  </Dropdown>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Quiz
