import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styles from './subject.module.css'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { custom, formselect, save } from '../MaterialUI';
import { Exit, Status } from '../../svg';
import * as yup from "yup";

import { useFormik } from 'formik';
import Api from '../../helper/api';
import Toastify from '../../helper/Toastify';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "none",
  padding: "27px 22px",
  height: "fit-content",
  display: "block",
  width: '380px',
  "&:focus": {
    outline: "none",
  },
};

const EditSubject = ({ handleClose, open, subjectEdit, refresh, setRefresh }) => {
  const api = new Api();
  const toast = new Toastify();
  // schema -----------
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    code: yup.string().required("Code is required"),
    status: yup.string().required("Status is required")
  })

  const {
    errors,
    values,
    handleChange,
    touched,
    setValues,
    handleBlur,
    handleSubmit,
    resetForm
  } = useFormik({
    initialValues: {
      name: "",
      code: "",
      status: ""
    },
    validationSchema: schema,
    onSubmit: () => {
      updateSubject();
    }
  })

  useEffect(() => {
    setValues(subjectEdit)
  }, [subjectEdit, open])

  // update subject
  const updateSubject = async () => {
    try {
      const { data, status } = await api.updateSubject(values, {
        id: subjectEdit._id
      })
      if (status === 200) {
        setRefresh(!refresh)
        resetForm();
        toast.success("Updated Successfully", "Your changes have been saved successfully")
        handleClose();
      }
    } catch (err) {
      if (err.response) {
        toast.error("Error occured", err.response.data.message)
      }
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.editHead}>
          <h2 className={styles.update}>Edit Subject</h2>
          <Exit handleClose={handleClose} />
        </div>
        <form className={styles.form}>
          <label>Subject Name</label>
          <br />
          <div className={styles.input}>
            <input type="text" name="name" onBlur={handleBlur} value={values.name} onChange={handleChange} placeholder='Enter' />
          </div>
          {
            errors.name && touched.name && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-14px" }}>{errors.name}</p>
          }

          <label>Code</label>
          <br />
          <div className={styles.input}>
            <input type="string" name="code" onBlur={handleBlur} value={values.code} onChange={handleChange} placeholder='Enter' />
          </div>
          {
            errors.code && touched.code && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-14px" }}>{errors.code}</p>
          }

          <label>Status</label>
          <br />
          <Select className={styles.formselect}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={formselect}
            IconComponent={Status}
            displayEmpty
            defaultValue=''
            name='status' value={values.status}
            onChange={handleChange}
          >
            <MenuItem value="">status</MenuItem>
            <MenuItem value="ACTIVE">Active</MenuItem>
            <MenuItem value="INACTIVE">Inactive</MenuItem>
          </Select>
          {
            errors.status && touched.status && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-5px" }}>{errors.status}</p>
          }
          {/* {
            errors.status && touched.status && <p className={styles.error}>{errors.status}</p>
          } */}
          <div className={styles.buttons}>
            <Button sx={custom} disableElevation onClick={handleClose} variant="contained">Cancel</Button>
            <Button sx={save} disableElevation onClick={handleSubmit} variant="contained">Update</Button>
          </div>
        </form>
      </Box>
    </Modal>
  )
}

export default EditSubject
