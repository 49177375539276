export const selectInput = {
    border: 'none',
    color: 'var(--Iris-80, #7879F1)',
    fontSize: "14px",
    fontWeight: "500 !important",
    fontFamily: "Inter",
    border: '1px solid #E5E5E5 !important',
    width: '117px',
    height: '38px',
    ".MuiOutlinedInput-notchedOutline": {
        border: " none !important"
    }
}
export const custom = {
    width: '90px',
    height: '42px',
    padding: '11px 20px',
    borderRadius: '6px',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(255, 255, 255, 0.70)',
    color: '#000',
    fontSize: '14px',
    textTransform: 'capitalize',
    fontWeight: '500',
    lineHeight: '130%', /* 18.2px */
    overflow: 'hidden',
    "&:hover": {
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(255, 255, 255, 0.70)',
    }
}

export const save = {
    height: '42px',
    padding: '11px 20px',
    borderRadius: '6px',
    background: 'linear-gradient(0deg, #000 0%, #000 100%), rgba(255, 255, 255, 0.70)',
    color: '#fff',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '130%', /* 18.2px */
    overflow: 'hidden',
    "&:hover": {
        background: 'linear-gradient(0deg, #000 0%, #000 100%), rgba(255, 255, 255, 0.70)',
    }
}
export const customradius = {
    height: '42px',
    padding: '11px 20px',
    borderRadius: '0px',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(255, 255, 255, 0.70)',
    color: '#000',
    fontSize: '14px',
    textTransform: 'capitalize',
    fontWeight: '500',
    lineHeight: '130%', /* 18.2px */
    overflow: 'hidden',
    "&:hover": {
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(255, 255, 255, 0.70)',
    }
}

export const saveradius = {
    height: '42px',
    padding: '11px 20px',
    borderRadius: '0px',
    background: 'linear-gradient(0deg, #000 0%, #000 100%), rgba(255, 255, 255, 0.70)',
    color: '#fff',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '130%', /* 18.2px */
    overflow: 'hidden',
    "&:hover": {
        background: 'linear-gradient(0deg, #000 0%, #000 100%), rgba(255, 255, 255, 0.70)',
    }
}
export const googlemap = {
    height: '42px',
    padding: '9px 17px',
    borderRadius: '6px',
    background: 'linear-gradient(0deg, #000 0%, #000 100%), rgba(255, 255, 255, 0.70)',
    color: '#fff',
    textTransform: 'capitalize',
    fontFamily: 'Work Sans',
    fontSize: '14px',
    fontWeight: '500',
    position: 'absolute',
    right: '10px',
    bottom: '8px',
    lineHeight: '130%', /* 18.2px */
    overflow: 'hidden',
    "&:hover": {
        background: 'linear-gradient(0deg, #000 0%, #000 100%), rgba(255, 255, 255, 0.70)',
    }
}
export const formselect = {
    width: '100%',
    color: '#8D8D8D',
    fontFamily: 'Poppins',
    lineHeight: '20px',
    fontSize: '14px',
    outline: 'none !important',
    borderRadius: '4px',
    border: '1px solid var(--Gray-100, #E9EAF0) !important',
    ".MuiSelect-select": {
        padding: '12px 18px !important'
    },
    ".MuiOutlinedInput-notchedOutline": {
        border: "none !important"
    },
    ".MuiInputBase-input": {
        padding: '12px 18px !important'
    }
}