import React, { useMemo, useEffect, useState, useRef, createRef } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styles from './Editor.module.css'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { custom, customradius, formselect, save, saveradius } from '../../pages/MaterialUI';
import { Exit, Status } from '../../svg';
import { styled } from '@mui/material/styles';
import { MdDelete } from "react-icons/md";

import Api from '../../helper/api';
import Toastify from '../../helper/Toastify';
import EditorQuill from './EditorQuill';
import { CircularProgress } from '@mui/material';
import Slider from '@mui/material/Slider';

import imageCompression from 'browser-image-compression';
import { QualityControl } from './QualityControl';

const style = {
    position: "absolute",
    top: "50%",
    left: "52%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    border: "none",
    padding: "16px 27px",
    height: "fit-content",
    display: "block",
    width: '970px',
    "&:focus": {
        outline: "none",
    },
};
const Editor = ({ handleClose, open, toOpen, questions, setQuestions }) => {
    const api = new Api();
    const toast = new Toastify();
    const [img, setImg] = useState(null);
    const [tempImg, setTempImg] = useState(null);
    const [value, setValue] = useState(10)
    const [loading, setLoading] = useState(false);
    const [openQuality, setQuality] = useState(false);

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const handleFile = async (e) => {
        try {
            let file = e.target.files[0];
            setQuality(true);
            setTempImg(file)
        } catch (err) {
            toast.error("Error occured", "File upload error")
        }
    }

    const handleImgDelete = () => {
        setImg(null)
        setTempImg(null)
        setQuestions((prev) => {
            const cloned = [...prev]
            cloned[toOpen.index][toOpen.title].img = []
            return [...cloned]
        })
    }

    const handleQuality = async () => {
        const options = {
            maxSizeMB: 3,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            initialQuality: (100 - value) / 100
        }

        try {
            const compressedFile = await imageCompression(tempImg, options);
            setImg(compressedFile);
            setQuality(false);
        } catch (err) {
            setQuality(false);
        }
    }

    const handleChange = (content) => {
        setQuestions((prev) => {
            const cloned = [...prev]
            cloned[toOpen.index][toOpen.title].html = content
            return [...cloned]
        })
    };

    const handleSubmit = async () => {
        try {
            setLoading(true)
            if (img) {
                const formData = new FormData();
                formData.append("image", img)
                const { data, status } = await api.uploadFile(formData)

                if (status === 200) {
                    // profileImg = data.data[0]
                    setQuestions((prev) => {
                        const cloned = [...prev]
                        cloned[toOpen.index][toOpen.title].img = [{
                            src: data.data[0]
                        }]
                        return [...cloned]
                    })
                }
            }
            setLoading(false)
            setImg(null)
            handleClose();
        } catch (err) {
            setLoading(false)
            toast.error("Error occured", "Failed to submit the changes")
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <QualityControl value={value} setValue={setValue} setOpen={setQuality} open={openQuality} handleSubmit={handleQuality} />
                <div className={styles.editHead}>
                    <h2 className={styles.update}>
                        {
                            toOpen.title === "sol" ? "Add solution" : toOpen.title === "ans" ? "Add Answer" : "Add Question"
                        }
                    </h2>
                    <Exit handleClose={handleClose} />
                </div>
                <form className={styles.form}>
                    <div className={styles.borders}>
                        <div className={styles.editor}>
                            <EditorQuill handleChange={handleChange} questions={questions} toOpen={toOpen} />
                        </div>
                    </div>
                    {
                        img
                            ? <div className={styles.borders} style={{ display: 'flex', alignItems: 'end', position: "relative" }}>
                                <div style={{ position: "relative" }}>
                                    <img src={URL.createObjectURL(img)} alt="" style={{ marginTop: 20, height: 90, width: "160px", objectFit: "contain" }} />
                                    <div className={styles.deleteIcon}>
                                        <MdDelete onClick={handleImgDelete} color='#000' size={22} />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <span style={{ marginLeft: 10, color: "#23BD33", fontWeight: 500, fontSize: 14, cursor: "pointer" }}>Image Uploaded </span>
                                        <span><strong> .</strong> --*--p</span>
                                    </div>
                                    <label htmlFor="file">
                                        <p style={{ marginLeft: 10, color: "#564FFD", fontWeight: 500, fontSize: 14, cursor: "pointer" }}>
                                            Replace Image
                                        </p>
                                        <input type="file" id='file' style={{ display: "none" }} onChange={handleFile} />
                                    </label>
                                </div>
                            </div>
                            : questions[toOpen.index][toOpen.title]?.img?.length > 0
                                ? <div className={styles.borders} style={{ display: 'flex', alignItems: 'end' }}>
                                    <div style={{ position: "relative" }}>
                                        <img src={questions[toOpen.index][toOpen?.title]?.img[0]?.src} alt="" style={{ marginTop: 20, height: 90, width: "160px", objectFit: "contain" }} />
                                        <div className={styles.deleteIcon}>
                                            <MdDelete onClick={handleImgDelete} color='#000' size={22} />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <span style={{ marginLeft: 10, color: "#23BD33", fontWeight: 500, fontSize: 14, cursor: "pointer" }}>Image Uploaded </span>
                                            <span><b> .</b> --*--p</span>
                                        </div>
                                        <label htmlFor="file">
                                            <p style={{ marginLeft: 10, color: "#564FFD", fontWeight: 500, fontSize: 14, cursor: "pointer" }}>
                                                Replace Image
                                            </p>
                                            <input type="file" id='file' style={{ display: "none" }} onChange={handleFile} />
                                        </label>
                                    </div>
                                </div>
                                : <span className={styles.borders} style={{ display: 'flex', flexDirection: 'column', margin: '40px 26px 10px 26px', alignItems: 'center', border: '1px #E9EAF0 solid' }}>
                                    <label style={{ fontWeight: '500', }}>Upload image</label>
                                    <Button sx={{ background: "transparent", color: "gray", textTransform: 'lowercase' }} component="label" disableElevation variant="contained">browse file
                                        <VisuallyHiddenInput type="file" onChange={handleFile} />
                                    </Button>
                                </span>
                    }
                    <br />
                    <span className={styles.borders}><b>Note:</b> Image size should be --*--p and less than --MB</span>
                    <br /><br />
                    <div className={styles.buttons} style={{ justifyContent: 'center' }}>
                        <Button sx={customradius} disableElevation onClick={handleClose} variant="contained">Cancel</Button>
                        <Button sx={{ ...saveradius, width: "125px" }} disabled={loading} disableElevation variant="contained" onClick={handleSubmit}>
                            {
                                loading
                                    ? <CircularProgress size={25} thickness={4.5} sx={{ color: "#fff" }} />
                                    : "Submit"
                            }
                        </Button>
                    </div>
                </form>
            </Box>
        </Modal>
    )
}

export default Editor
