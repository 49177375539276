import React, { useEffect, useState } from 'react'
import { Action, Active, Inactive, Left, Right, RightArrow, Search, Status } from '../../svg'
import { formselect, selectInput } from '../MaterialUI';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import styles from '../subjects/subject.module.css'
import { custom, save } from '../MaterialUI';
import { MenuButton, Dropdown, Menu, MenuItem as MenuAction } from '@mui/joy'
import EditLevel from './EditLevel';

import Selects from 'react-select'
import Api from '../../helper/api';
import * as yup from "yup";
import Toastify from '../../helper/Toastify';
import { useFormik } from 'formik';
import Delete from '../../shared/Delete/Delete';


const Levels = () => {
  const toast = new Toastify();
  const api = new Api();

  // states-------------
  const [open, setOpen] = useState(false);
  const [openDlt, setOpenDlt] = useState(false);
  const [levelToDelete, setLevelToDelete] = useState("")
  const [statuss, setStatus] = useState("");
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(50)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0);
  const [levelToEdit, setLevelToEdit] = useState({
    name: '', status: '', code: ''
  })
  const [levels, setLevels] = useState([]);
  const [refresh, setRefresh] = useState(false);

  // calculate start & end of items -------------------
  const start = (page - 1) * limit + 1;
  const end =
    totalPages === page
      ? totalItems
      : (page - 1) * limit + limit;


  // increment the page------------------------
  const increment = () => {
    if (page < totalPages) {
      setPage((prev) => prev + 1)
    }
  }

  // decrement the page------------------------
  const decrement = () => {
    if (page > 1) {
      setPage((prev) => prev - 1)
    }
  }

  // open the edit popup----------------------
  const handleOpen = (topic) => {
    setOpen(true)
    setLevelToEdit(topic)
  };

  // close the edit popup---------------------
  const handleClose = () => setOpen(false);

  // fetch all Levels -------------------------
  const AllLevel = async () => {
    try {
      const { data, status } = await api.level({}, `?search=${search}&page=${page}&status=${statuss}&limit=${limit}`);
      if (status === 200) {
        setLevels(data.data)
        setTotalPages(data.totalPages)
        setTotalItems(data.totalItems)
      }
    } catch (err) {
      console.log(err)
    }
  }


  // run on initial render-------------------
  useEffect(() => {
    AllLevel();
  }, [refresh, page, search, statuss, limit])

  // schema --------------------
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    status: yup.string().required("Status is required"),
    code: yup.string().required("Code is required")
  })

  // formik to handle forms data --------------------
  const {
    errors,
    values,
    handleChange,
    touched,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      name: "",
      status: '',
      code: ''
    },
    validationSchema: schema,
    onSubmit: () => {
      AddLevel();
    }
  })

  // add subject -----------------------
  const AddLevel = async () => {
    try {
      const { data, status } = await api.addLevel(values)
      if (status === 200) {
        setRefresh(!refresh)
        resetForm();
        toast.success("Saved Successfully", "Your changes have been saved successfully")
      }
    } catch (err) {
      if (err.response) {
        toast.error("Error occured", err.response.data.message)
      }
    }
  }

  // delete chapter ------------------------
  const handleDelete = async () => {
    try {
      const { data, status } = await api.trashLevel("", { id: levelToDelete })
      if (status === 200) {
        setRefresh(!refresh)
        setOpenDlt(false)
        setLevelToDelete("")
        toast.success("Deleted Successfully", "Your changes have been saved successfully")
      }
    } catch (err) {
      if (err.response) {
        toast.error("Error occured", err.response.data.message)
      }
    }
  }

  return (
    <div className={styles.container}>
      <EditLevel setRefresh={setRefresh} refresh={refresh} handleClose={handleClose} open={open} levelToEdit={levelToEdit} />
      <Delete handleClose={() => setOpenDlt(false)} open={openDlt} handleDelete={handleDelete} />
      <div className={styles.head}>
        <h1>Master Data</h1>
        <span>
          home <RightArrow /> Master Data <RightArrow /> <span style={{ color: 'var(--Gray-900, #191B1C)' }}>Levels</span>
        </span>
      </div>
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.subhead}>
            <Select className={styles.selectinput}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={selectInput}
              value={statuss}
              onChange={(e) => setStatus(e.target.value)}
              IconComponent={Status}
              displayEmpty
              defaultValue=''
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="ACTIVE">Active</MenuItem>
              <MenuItem value="INACTIVE">Inactive</MenuItem>
            </Select>
            <div className={styles.search}><Search /> <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search' /></div>
          </div>
          <div className={styles.header}>
            <div className={styles.first}>no.</div>
            <div className={styles.sixth}>Levels</div>
            <div className={styles.fifth}>Code</div>
            <div className={styles.sixth}>status</div>
            <div style={{ width: '43%' }} className={`${styles.sixth}`}>action</div>
          </div>
          {
            levels.map((level, index) => (
              <div className={styles.info}>
                <div className={styles.first}>{(page - 1) * 10 + index + 1}</div>
                <div className={styles.sixth}>{level.name}</div>
                <div className={styles.fifth}>{level.code}</div>
                <div className={`${styles.sixth}`}>
                  {
                    level.status === 'ACTIVE'
                      ? <span className={styles.active}>
                        <Active /> Active
                      </span>
                      : <span className={styles.inactive}>
                        <Inactive /> Inactive
                      </span>
                  }
                </div>
                <div style={{ width: '43%' }}>
                  <Dropdown>
                    <MenuButton style={{ border: 'none', padding: '0' }}><Action /></MenuButton>
                    <Menu>
                      <MenuAction onClick={() => handleOpen(level)}>Edit</MenuAction>
                      <MenuAction onClick={() => {
                        setOpenDlt(true)
                        setLevelToDelete(level._id)
                      }}>Delete</MenuAction>
                    </Menu>
                  </Dropdown>
                </div>
              </div>
            ))
          }
          <div className={styles.pagination}>
            <div>
              Rows per page:
              <Dropdown>
                <MenuButton style={{ border: 'none', padding: '0' }}>
                  <div>
                    {limit}
                  </div>
                </MenuButton>
                <Menu>
                  <MenuAction onClick={() => setLimit(15)} >15</MenuAction>
                  <MenuAction onClick={() => setLimit(25)} >25</MenuAction>
                  <MenuAction onClick={() => setLimit(50)} >50</MenuAction>
                  <MenuAction onClick={() => setLimit(75)} >75</MenuAction>
                  <MenuAction onClick={() => setLimit(100)} >100</MenuAction>
                </Menu>
              </Dropdown>
            </div>
            <div>Showing {start} to {end} of {totalItems} entries</div>
            <div>
              <Right handleClick={decrement} />
              {/* <p>01</p> */}
              <p className={styles.onPage}>{page}</p>
              <Left handleClick={increment} />
            </div>
          </div>
        </div>

        <div className={styles.right}>
          <h5>Add New Level</h5>

          <label>Level Name</label>
          <br />
          <div className={styles.input}>
            <input type="text" placeholder='Enter' name='name' value={values.name} onChange={handleChange} onBlur={handleBlur} />
          </div>
          {
            errors.name && touched.name && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-14px" }}>{errors.name}</p>
          }

          <label>Code</label>
          <br />
          <div className={styles.input}>
            <input type="text" placeholder='Enter' name='code' value={values.code} onChange={handleChange} onBlur={handleBlur} />
          </div>
          {
            errors.code && touched.code && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-14px" }}>{errors.code}</p>
          }

          <label>Status*</label>
          <br />
          <Select className={styles.formselect}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={formselect}
            onChange={handleChange}
            onBlur={handleBlur}
            name='status'
            value={values.status}
            IconComponent={Status}
            displayEmpty
            defaultValue=''
          >
            <MenuItem value="">status</MenuItem>
            <MenuItem value="ACTIVE">Active</MenuItem>
            <MenuItem value="INACTIVE">Inactive</MenuItem>
          </Select>
          {
            errors.status && touched.status && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-5px" }}>{errors.status}</p>
          }
          <div className={styles.buttons}>
            <Button sx={custom} onClick={() => resetForm()} disableElevation variant="contained">Cancel</Button>
            <Button sx={save} onClick={handleSubmit} disableElevation variant="contained">save</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Levels
