import styles from '../subjects/subject.module.css'
import React, { useEffect, useState } from 'react'
import { Action, Active, Inactive, Left, Right, RightArrow, Search, Status } from '../../svg'
import { formselect, selectInput } from '../MaterialUI';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Selects from 'react-select'
import { custom, save } from '../MaterialUI';
import { MenuButton, Dropdown, Menu, MenuItem as MenuAction } from '@mui/joy'
import EditChapter from './EditChapter';
import Api from '../../helper/api';
import * as yup from "yup";
import Toastify from '../../helper/Toastify';
import { useFormik } from 'formik';
import Delete from '../../shared/Delete/Delete';


const TrashChapters = () => {
    const toast = new Toastify();
    const api = new Api();

    // states-------------
    const [openDlt, setOpenDlt] = useState(false);
    const [chapterToDelete, setChapterToDelete] = useState("")
    const [statuss, setStatus] = useState("");
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const [totalPages, setTotalPages] = useState(1)
    const [totalItems, setTotalItems] = useState(0);
    const [chapters, setChapters] = useState([]);
    const [refresh, setRefresh] = useState(false);

    // calculate start & end of items -------------------
    const start = (page - 1) * limit + 1;
    const end =
        totalPages === page
            ? totalItems
            : (page - 1) * limit + limit;

    // increment the page------------------------
    const increment = () => {
        if (page < totalPages) {
            setPage((prev) => prev + 1)
        }
    }

    // decrement the page------------------------
    const decrement = () => {
        if (page > 1) {
            setPage((prev) => prev - 1)
        }
    }


    // fetch all chapters -------------------------
    const AllChapter = async () => {
        try {
            const { data, status } = await api.allTrashedChapter({}, `?search=${search}&page=${page}&status=${statuss}&limit=${limit}`);
            if (status === 200) {
                setChapters(data.data)
                setTotalPages(data.totalPages)
                setTotalItems(data.totalItems)
            }
        } catch (err) {
            console.log(err)
        }
    }

    // run on initial render-------------------
    useEffect(() => {
        AllChapter();
    }, [refresh, page, search, statuss, limit])


    // delete chapter ------------------------
    const handleDelete = async () => {
        try {
            const { data, status } = await api.deleteChapter("", { id: chapterToDelete })
            if (status === 200) {
                setRefresh(!refresh)
                setOpenDlt(false)
                setChapterToDelete("")
                toast.success("Deleted Successfully", "Your changes have been saved successfully")
            }
        } catch (err) {
            if (err.response) {
                toast.error("Error occured", err.response.data.message)
            }
        }
    }

    const handleRestore = async (id) => {
        try {
            const { data, status } = await api.restoreChapter("", { id: id })
            if (status === 200) {
                setRefresh(!refresh)
                setOpenDlt(false)
                setChapterToDelete("")
                toast.success("Restored Successfully", "Your changes have been saved successfully")
            }
        } catch (err) {
            if (err.response) {
                toast.error("Error occured", err.response.data.message)
            }
        }
    }

    return (
        <div className={styles.container}>
            <Delete handleClose={() => setOpenDlt(false)} open={openDlt} handleDelete={handleDelete} />
            <div className={styles.head}>
                <h1>Master Data</h1>
                <span>
                    home <RightArrow /> Master Data <RightArrow /> <span style={{ color: 'var(--Gray-900, #191B1C)' }}>Chapter</span>
                </span>
            </div>
            <div className={styles.main}>
                <div className={styles.left}>
                    <div className={styles.subhead}>
                        <Select className={styles.selectinput}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            sx={selectInput}
                            value={statuss}
                            onChange={(e) => setStatus(e.target.value)}
                            IconComponent={Status}
                            displayEmpty
                            defaultValue=''
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="ACTIVE">Active</MenuItem>
                            <MenuItem value="INACTIVE">Inactive</MenuItem>
                        </Select>
                        <div className={styles.search}><Search /> <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search' /></div>
                    </div>
                    <div className={styles.header}>
                        <div className={styles.fourth}>no.</div>
                        <div className={styles.sixth}>subject name</div>
                        <div className={styles.sixth}>Chapter name</div>
                        <div className={styles.fifth}>code</div>
                        <div className={styles.fifth}>status</div>
                        <div className={`${styles.fifth} ${styles.flexEnd}`}>action</div>
                    </div>

                    {
                        chapters.map((chapter, index) => (
                            <div className={styles.info}>
                                <div className={styles.fourth}>{(page - 1) * 10 + index + 1}</div>
                                <div className={styles.sixth}>{chapter?.subject.name}</div>
                                <div className={styles.sixth}>{chapter.name}</div>
                                <div className={styles.fifth}>{chapter.code}</div>
                                <div className={styles.fifth}>
                                    {
                                        chapter.status === 'ACTIVE'
                                            ? <span className={styles.active}>
                                                <Active /> Active
                                            </span>
                                            : <span className={styles.inactive}>
                                                <Inactive /> Inactive
                                            </span>
                                    }
                                </div>
                                <div className={`${styles.fifth} ${styles.flexEnd}`}><Dropdown>
                                    <MenuButton style={{ border: 'none', padding: '0' }}><Action /></MenuButton>
                                    <Menu>
                                        <MenuAction onClick={() => handleRestore(chapter._id)}>Restore</MenuAction>
                                        <MenuAction onClick={() => {
                                            setOpenDlt(true)
                                            setChapterToDelete(chapter._id)
                                        }}>Delete</MenuAction>
                                    </Menu>
                                </Dropdown></div>
                            </div>
                        ))
                    }

                    <div className={styles.pagination}>
                        <div>
                            Rows per page:
                            <Dropdown>
                                <MenuButton style={{ border: 'none', padding: '0' }}>
                                    <div>
                                        {limit}
                                    </div>
                                </MenuButton>
                                <Menu>
                                    <MenuAction onClick={() => setLimit(15)} >15</MenuAction>
                                    <MenuAction onClick={() => setLimit(25)} >25</MenuAction>
                                    <MenuAction onClick={() => setLimit(50)} >50</MenuAction>
                                    <MenuAction onClick={() => setLimit(75)} >75</MenuAction>
                                    <MenuAction onClick={() => setLimit(100)} >100</MenuAction>
                                </Menu>
                            </Dropdown>
                        </div>
                        <div>Showing {start} to {end} of {totalItems} entries</div>
                        <div>
                            <Right handleClick={decrement} />
                            {/* <p>01</p> */}
                            <p className={styles.onPage}>{page}</p>
                            <Left handleClick={increment} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrashChapters
