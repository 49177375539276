import React, { useEffect, useState } from 'react'
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import { useLocation, useNavigate } from 'react-router-dom';

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [layout, setLayout] = useState(false);
  const path = location.pathname.split('/')[1];

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      setLayout(true)
      if (!path) {
        navigate('/dashboard')
      }
    } else {
      navigate('/')
    }
  }, [token, path])

  return (
    <>
      {layout ? <><Header /> <Sidebar children={children} /></> : children}
    </>
  )
}

export default Layout;
