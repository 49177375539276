import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styles from '../subjects/subject.module.css'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { custom, formselect, save } from '../MaterialUI';
import { Exit, Status, Student } from '../../svg';

import Api from '../../helper/api';
import * as yup from "yup";
import Toastify from '../../helper/Toastify';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    border: "none",
    padding: "27px 22px",
    height: "fit-content",
    display: "block",
    overflowY: 'auto',
    marginTop: '50px',
    width: '380px',
    "&:focus": {
        outline: "none",
    },
};


const UpdatePlan = ({ handleClose, open, studentToUpdate, plans }) => {
    const toast = new Toastify();
    const api = new Api();
    const [loading, setLoading] = useState(false);

    console.log("plans", plans)


    // schema --------------------
    const schema = yup.object().shape({
        plan_id: yup.string().required("Plan is required")
    })


    // formik to handle forms data --------------------
    const {
        errors,
        values,
        handleChange,
        touched,
        setValues,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm
    } = useFormik({
        initialValues: {
            plan_id: '',
        },
        validationSchema: schema,
        onSubmit: () => {
            savePlan();
        }
    })

    const savePlan = async () => {
        try {
            setLoading(true)
            const { data, status } = await api.updateStudentPlan(values, { id: studentToUpdate })

            if (status === 200) {
                toast.success("Plan activated successfully")
                setLoading(false)
                handleClose();
            }
        } catch (err) {
            setLoading(false)
            toast.error(err.config.data.message || "Error activating plan")
        }
    }


    const close = () => {
        handleClose();
        resetForm();
    }

    return (
        <Modal
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className={styles.editHead}>
                    <h2 className={styles.update}>Update Plan</h2>
                    <Exit handleClose={close} />
                </div>
                <form className={styles.form}>

                    <label>Select Plan</label>
                    <br />

                    <Select className={styles.formselect}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        sx={formselect}
                        name='plan_id'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.status}
                        IconComponent={Status}
                        displayEmpty
                        defaultValue=''
                    >
                        {
                            plans.map((plan) => (
                                <MenuItem value={plan._id}>{plan.title}</MenuItem>
                            ))
                        }
                    </Select>
                    {
                        errors.plan_id && touched.plan_id && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-5px" }}>{errors.plan_id}</p>
                    }
                    <div className={styles.buttons}>
                        <Button sx={custom} disableElevation onClick={close} variant="contained">Cancel</Button>
                        <Button sx={{...save, width: 90}} disableElevation disabled={loading} onClick={handleSubmit} variant="contained">
                            {
                                loading
                                    ? <CircularProgress size={25} thickness={4.5} sx={{ color: "#fff" }} />
                                    : "Save"
                            }
                        </Button>
                    </div>
                </form>
            </Box>
        </Modal>
    )
}

export default UpdatePlan
