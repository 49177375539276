import React, { useEffect, useMemo, useState } from 'react'
import styles from '../quiz/quiz.module.css'
import { Action, AnswerKey, Darkmenu, DeleteIcon, EditIcon, Info, LightDelete, Lightedit, Menu, Next, Opened, Plusicon, RightArrow, Search, Status, Table } from '../../svg'
import { Button, CircularProgress, MenuItem, Select } from '@mui/material'
import { custom, customradius, formselect, save, saveradius, selectInput } from '../MaterialUI'
import { useNavigate } from 'react-router-dom'
import Api from '../../helper/api'
import Toastify from '../../helper/Toastify'

const Preview = ({ id, course, handleCancel, setTitle, showBtn }) => {
    const navigate = useNavigate();
    const api = new Api();
    const toast = new Toastify();

    // states --------------------------
    // ---------------------------------
    const [opened, setOpened] = useState(null)
    const [view, setView] = useState("")
    const [loading, setLoading] = useState(false)
    const [details, setDetails] = useState({ questions: [], chapter: "" });

    // run useEffect and save all the fields here
    useEffect(() => {
        id && getTest();
    }, [id])

    const getTest = async () => {
        try {
            const { data, status } = await api.getQuizById("", { id })

            if (status === 200) {
                setDetails(data.data)
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleOpen = (id, views) => {
        const exist = opened === id && view === views;
        if (exist) {
            setOpened(null)
            setView("")
        } else {
            setOpened(id)
            setView(views)
        }
    }


    // publish -----------------------
    const publish = async () => {
        try {
            setLoading(true)
            const { data, status } = await api.updateQuiz({ state: 3 }, { id })
            if (status === 200) {
                toast.success("Published Successfully", "Your changes have been published successfully")
                setLoading(false)
                setTimeout(() => {
                    navigate("/qa/quiz")
                }, 1500);
            }
        } catch (err) {
            if (err.response) {
                toast.error("Error occured", err.response.data.message)
            }
            setLoading(false)
        }
    }
    return (
        <>
            <div className={styles.headerTitle}>
                <span>preview :
                    {
                        course.chapters.find((chapter) => chapter._id === details.chapter)?.name
                    }
                </span>
            </div>
            <div style={{ padding: '24px' }}>
                <Button sx={{ width: '100%', height: '52px', fontSize: '24px' }} disableElevation variant="contained">
                    {
                        course.topics.find((topic) => topic._id === details.topic)?.name
                    }
                </Button>
                <p style={{ textAlign: 'center', color: '#232521', fontWeight: '600' }}>
                    {
                        course.levels.find((level) => level._id === details?.level)?.name
                    }
                    {""} Q&A</p>

            </div>
            <div className={styles.row}>
                {
                    details.questions.map((ques, index) => (
                        <>
                            <div className={styles.column}>
                                <div className={styles.columnhead}>Question {index + 1}</div>
                                <div className={styles.columncontent}>
                                    <div className="ql-editor">
                                        <div className="reportLayout">
                                            <div dangerouslySetInnerHTML={{ __html: ques?.que?.html }}>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        ques.que?.img?.length > 0 && <img style={{ width: '100%' }} src={ques.que?.img[0]?.src} alt="" />
                                    }
                                </div>
                            </div>
                            <div className={styles.column}>
                                <div className={styles.columnhead} onClick={() => handleOpen(index, "ans")} style={{ background: view === "ans" && opened === index ? '' : '#6A6B6C' }}>answer key {opened === 1 ? <AnswerKey /> : <Opened />}</div>
                                {opened === index && view === "ans"
                                    && <div className={styles.columncontent}>
                                        <div className="ql-editor">
                                            <div className="reportLayout">
                                                <div dangerouslySetInnerHTML={{ __html: ques?.ans?.html }}>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            ques.ans?.img?.length > 0 && <img style={{ width: '100%' }} src={ques.ans?.img[0].src} alt="" />
                                        }
                                    </div>
                                }
                                <br />
                                <div className={styles.columnhead} onClick={() => handleOpen(index, "sol")} style={{ background: view === "sol" && opened === index ? '' : '#6A6B6C' }}>solution{opened === 2 ? <AnswerKey /> : <Opened />}</div>
                                {
                                    opened === index && view === "sol"
                                    && <div className={styles.columncontent}>
                                        <div className="ql-editor">
                                            <div className="reportLayout">
                                                <div dangerouslySetInnerHTML={{ __html: ques?.sol?.html }}>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            ques.sol?.img?.length > 0 && <img src={ques.sol?.img[0].src} alt="" />
                                        }
                                    </div>
                                }
                            </div>
                        </>
                    ))
                }
            </div>

            <div className={styles.end}>
                <span className={styles.line}></span>end of q&a <span className={styles.line}></span>
            </div>
            {
                !showBtn &&
                < div className={styles.buttonquiz}>
                    <Button sx={customradius} onClick={() => setTitle(2)} disableElevation variant="contained">Cancel</Button>
                    <Button sx={{ ...saveradius, width: "125px" }} disabled={loading} disableElevation variant="contained" onClick={publish}>
                        {
                            loading
                                ? <CircularProgress size={25} thickness={4.5} sx={{ color: "#fff" }} />
                                : "Publish"
                        }
                    </Button>
                </div >
            }
        </>
    )
}

export default Preview