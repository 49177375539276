import React, { createRef, useEffect, useMemo, useRef, useState } from 'react'
import styles from '../quiz/quiz.module.css'
import { Action, AnswerKey, Darkmenu, DeleteIcon, EditIcon, Info, LightDelete, Lightedit, Menu, Next, Opened, Plusicon, Preview, RightArrow, Search, Status, Table } from '../../svg'
import { Button, CircularProgress, MenuItem, Select } from '@mui/material'
import { custom, customradius, formselect, save, saveradius, selectInput } from '../MaterialUI'
import { useNavigate } from 'react-router-dom'
import Api from '../../helper/api'
import Selects from 'react-select'
import { useFormik } from 'formik'
import * as yup from "yup";
import Toastify from '../../helper/Toastify'

import ReactQuill, { Quill } from 'react-quill'
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import 'quill/dist/quill.snow.css'

import katex from 'katex';
import "katex/dist/katex.min.css";

// import jquery
import "../../shared/MathQuill/jquery"

// MathQuill dependency
import "@edtr-io/mathquill/build/mathquill.js";
import "@edtr-io/mathquill/build/mathquill.css";

// mathquill4quill include
import mathquill4quill from "../../shared/MathQuill/mathquill4quill";
import "../../shared/MathQuill/mathquill4quill.css";
// import "mathquill4quill/mathquill4quill.css";

import ImageResize from 'quill-image-resize-module-react';
import BlotFormatter from 'quill-blot-formatter';
import { CUSTOM_OPERATORS } from '../../helper/quill'
import CustomImage from '../../shared/CustomImage'
import * as MathType from "@wiris/mathtype-generic";
let Font = Quill.import('attributors/style/font');
Font.whitelist = ['Ubuntu', 'Raleway', 'Roboto', "Serif"];


window.katex = katex;
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register("formats/image", CustomImage);
Quill.register(Font, true);
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)


const BasicInfo = ({ course, id, setId, setTitle, handleCancel }) => {
    const api = new Api();
    const toast = new Toastify();
    const macros = useRef(null)

    const [courseValue, setCourseValue] = useState({
        subject: "",
        chapter: "",
        level: "",
        topic: ""
    });

    const [loading, setLoading] = useState(false);

    // schema --------------------
    const schema = yup.object().shape({
        subject: yup.string().required("Subject is required"),
        chapter: yup.string().required("Chapter is required"),
        topic: yup.string().required("Topic is required"),
        level: yup.string().required("Level is required"),
        status: yup.string().required("Status is required"),
    })

    // formik to handle forms data --------------------
    const {
        errors,
        values,
        handleChange,
        touched,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm
    } = useFormik({
        initialValues: {
            subject: "",
            chapter: "",
            status: '',
            level: '',
            desc: '',
            topic: ""
        },
        validationSchema: schema,
        onSubmit: () => {
            console.log("running")
            id ? updateBasicInfo() : addBasicInfo()
        }
    })

    // add basic info -----------------------
    const addBasicInfo = async () => {
        try {
            setLoading(true)
            const { data, status } = await api.addQuiz({ ...values, state: 1 })
            if (status === 200) {
                toast.success("Saved Successfully", "Your changes have been saved successfully")
                setId(data.data._id)
                setLoading(false)
                setTitle(2)
            }
        } catch (err) {
            if (err.response) {
                toast.error("Error occured", err.response.data.message)
            }
            setLoading(false)
        }
    }

    // update Basic info -----------------------
    const updateBasicInfo = async () => {
        try {
            setLoading(true)
            const { data, status } = await api.updateQuiz({ ...values, state: 1 }, { id })
            if (status === 200) {
                toast.success("Saved Successfully", "Your changes have been saved successfully")
                setLoading(false)
                setTitle(2)
            }
        } catch (err) {
            if (err.response) {
                toast.error("Error occured", err.response.data.message)
            }
            setLoading(false)
        }
    }

    const handleValues = (prop, value) => {
        setCourseValue((prev) => {
            return { ...prev, [prop]: value }
        })
        setFieldValue(prop, value.value)
    }

    useEffect(() => {
        const chapter = course.chapters.find((chapter) => chapter._id === courseValue.chapter.value)

        if (course?.chapters?.length > 0) {
            if (chapter?.subject !== courseValue.subject.value) {
                handleValues("chapter", "")
            }
        }

        const topic = course.topics.find((topic) => topic._id === courseValue.topic.value)

        if (course?.topics?.length > 0) {
            if (topic?.chapter !== courseValue.chapter.value) {
                handleValues("topic", "")
            }
        }

    }, [courseValue.chapter.value, courseValue.subject.value]);


    // run useEffect and save all the fields here
    useEffect(() => {
        id && getQuiz();
    }, [id])


    const getQuiz = async () => {
        try {
            const { data, status } = await api.getQuizById("", { id })

            if (status === 200) {
                setValues({
                    subject: data.data.subject,
                    chapter: data.data.chapter,
                    topic: data.data.topic,
                    desc: data.data.desc,
                    level: data.data.level,
                    status: data.data.status
                })

                const subject = course.subjects.find(subject => subject._id === data.data.subject)
                const chapter = course.chapters.find(chapter => chapter._id === data.data.chapter)
                const topic = course.topics.find(topic => topic._id === data.data.topic)
                const level = course.levels.find(level => level._id === data.data.level)

                setCourseValue({
                    subject: { value: subject._id, label: subject.name },
                    chapter: { value: chapter._id, label: chapter.name },
                    topic: { value: topic._id, label: topic.name },
                    level: { value: level._id, label: level.name }
                })
            }
        } catch (err) {
            console.log(err);
        }
    }


    // editor----------------------
    // ---------------------------

    const imageHandler = async () => {
        const input = document.createElement('input')

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            let file = input.files[0];

            try {
                if (file.size > 1000000) {
                    toast.error("Error occured", "Please upload file of less than 1 mb")
                } else {
                    const formData = new FormData();
                    formData.append("image", file)
                    const { data, status } = await api.uploadFile(formData);

                    const range = macros.current.getEditorSelection();
                    var res = data.data[0];

                    macros.current.getEditor().insertEmbed(range.index, 'image', res);
                }
            } catch (err) {
                toast.error("Error occured", "File upload error")
            }
        };
    }

    const pasteImageHandler = async (imageDataUrl, type, imageData) => {
        try {
            const blob = imageData.toBlob()
            const file = imageData.toFile()

            async function fetchUrl(file) {
                try {
                    toast.success("", "Uploading image")

                    // generate a form data
                    const formData = new FormData();
                    formData.append("image", file)
                    const { data, status } = await api.uploadFile(formData);

                    const json = data.data[0]
                    toast.success("", "Image uploaded")
                    return json;
                } catch (err) {
                    toast.warning("", "Error uploading Image")
                }
            }

            const url = await fetchUrl(file);

            const range = macros.current.selection;
            const index = range?.index

            macros.current.getEditor().insertEmbed(index, 'image', url);

        } catch (errr) {
            console.log(errr)
        }
    }

    const modules = useMemo(() => (
        {
            formula: true,
            toolbar: {
                container: [
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }, {
                        'font': ['Ubuntu', 'Raleway', 'Roboto', "Serif"]
                    }],
                    [{ size: ["small", false, "large", "huge"] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                        { align: [] }
                    ],
                    ['link', 'image'],
                    [{ 'align': [] }],
                    [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
                ],
                'handlers': {
                    image: imageHandler
                },
            },
            imageResize: {
                parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize']
            },
            imageDropAndPaste: {
                // add an custom image handler
                handler: pasteImageHandler,
            },
            blotFormatter: {},
        }
    ), [])

    var formats = [
        "header", "height", "bold", "italic", "font",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "image", "align", "size", "formula", "width", "style"
    ];

    const handleEditor = (content) => {
        setFieldValue("desc", content)
    };


    useEffect(() => {
        const ToolBarRef = document.getElementsByClassName("ql-toolbar")[0]
        let genericIntegrationInstance;
        if (ToolBarRef) {
            genericIntegrationInstance = new window.WirisPlugin.GenericIntegration({
                target: document.getElementsByClassName("ql-editor")[0],
                toolbar: ToolBarRef
            });

            genericIntegrationInstance.init();
            genericIntegrationInstance.listeners.fire("onTargetReady", {});
        }
        return () => {
            // wrs_modal_dialogContainer
            if (genericIntegrationInstance) {
                const propertyDescriptors = Object.getOwnPropertyDescriptors(genericIntegrationInstance);
                console.log("genericinte---------------", propertyDescriptors)
                // window.WirisPlugin = {}
                // genericIntegrationInstance.destroy();
            }
        }
    }, [])

    return (
        <>
            <div className={styles.headerTitle} >basic information</div>
            <div className={styles.inputboxes}>
                <span>
                    <label>course subject</label><br />
                    <Selects
                        options={course.subjects.map((subject) => {
                            return {
                                value: subject._id,
                                label: subject.name
                            }
                        })}
                        onChange={(e) => handleValues("subject", e)}
                        name='subject'
                        onBlur={handleBlur}
                        value={courseValue.subject}
                        styles={{
                            input: (styles) => ({ ...styles, fontSize: "5px", color: "#8C94A3 !important" }),
                            container: (styles) => ({ ...styles, width: "100%", marginTop: "6px" }),
                            control: () => ({ display: "flex", border: "1px solid #E9EAF0", borderRadius: "4px", outline: "none", padding: 4, color: "#8C94A3 !important" }),
                            placeholder: (styles) => ({ ...styles, fontSize: 14, color: "#8C94A3" }),

                        }}
                    />
                    {
                        errors.subject && touched.subject && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-5px" }}>{errors.subject}</p>
                    }
                </span>
                <span>
                    <label>subject chapter</label><br />
                    <Selects
                        options={course.chapters.filter((chapter) => chapter.subject.includes(courseValue.subject.value || "")).map((chapter) => {
                            return {
                                value: chapter._id,
                                label: chapter.name
                            }
                        })}
                        onChange={(e) => handleValues("chapter", e)}
                        name='chapter'
                        onBlur={handleBlur}
                        value={courseValue.chapter}
                        styles={{
                            input: (styles) => ({ ...styles, fontSize: "5px", color: "#8C94A3 !important" }),
                            container: (styles) => ({ ...styles, width: "100%", marginTop: "6px" }),
                            control: () => ({ display: "flex", border: "1px solid #E9EAF0", borderRadius: "4px", outline: "none", padding: 4, color: "#8C94A3 !important" }),
                            placeholder: (styles) => ({ ...styles, fontSize: 14, color: "#8C94A3" }),

                        }}
                    />
                    {
                        errors.chapter && touched.chapter && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-5px" }}>{errors.chapter}</p>
                    }
                </span>
                <span>
                    <label>Chapter Topic</label><br />
                    <Selects
                        options={course.topics.filter((topic) => topic.chapter.includes(courseValue.chapter.value || "")).map((topic) => {
                            return {
                                value: topic._id,
                                label: topic.name
                            }
                        })}
                        onChange={(e) => handleValues("topic", e)}
                        name='topic'
                        onBlur={handleBlur}
                        value={courseValue.topic}
                        styles={{
                            input: (styles) => ({ ...styles, fontSize: "5px", color: "#8C94A3 !important" }),
                            container: (styles) => ({ ...styles, width: "100%", marginTop: "6px" }),
                            control: () => ({ display: "flex", border: "1px solid #E9EAF0", borderRadius: "4px", outline: "none", padding: 4, color: "#8C94A3 !important" }),
                            placeholder: (styles) => ({ ...styles, fontSize: 14, color: "#8C94A3" }),

                        }}
                    />
                    {
                        errors.topic && touched.topic && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-5px" }}>{errors.topic}</p>
                    }
                </span>
                <span>
                    <label>course level</label><br />
                    <Selects
                        options={course.levels.map((level) => {
                            return {
                                value: level._id,
                                label: level.name
                            }
                        })}
                        onChange={(e) => handleValues("level", e)}
                        name='level'
                        onBlur={handleBlur}
                        value={courseValue.level}
                        styles={{
                            input: (styles) => ({ ...styles, fontSize: "5px", color: "#8C94A3 !important" }),
                            container: (styles) => ({ ...styles, width: "100%", marginTop: "6px" }),
                            control: () => ({ display: "flex", border: "1px solid #E9EAF0", borderRadius: "4px", outline: "none", padding: 4, color: "#8C94A3 !important" }),
                            placeholder: (styles) => ({ ...styles, fontSize: 14, color: "#8C94A3" }),

                        }}
                    />
                    {
                        errors.level && touched.level && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-5px" }}>{errors.level}</p>
                    }
                </span>
                <span>
                    <label>status</label><br />
                    <Select className={styles.formselect}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        sx={formselect}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="status"
                        value={values.status}
                        IconComponent={Status}
                        displayEmpty
                        defaultValue=''
                    >
                        <MenuItem value="">status</MenuItem>
                        <MenuItem value="ACTIVE">Active</MenuItem>
                        <MenuItem value="INACTIVE">Inactive</MenuItem>
                    </Select>
                    {
                        errors.status && touched.status && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-5px" }}>{errors.status}</p>
                    }
                </span>
            </div>

            <div className={styles.data} >
                <ReactQuill
                    theme="snow"
                    ref={macros}
                    modules={modules}
                    formats={formats}
                    placeholder="write your content ...."
                    onChange={handleEditor}
                    value={values.desc}
                    style={{
                        height: "380px",
                        backgroundColor: 'rgba(227, 241, 254, 0.4)',
                        '&.ql-editor': {
                            border: 'none !important',
                            backgroundColor: 'rgba(227, 241, 254, 0.4)',
                        },
                        '&.ql-toolbar.ql-snow': {
                            border: 'none',
                        },
                    }}
                >
                </ReactQuill>
            </div>
            <div className={styles.buttons}>
                <Button sx={customradius} onClick={handleCancel} disableElevation variant="contained">Cancel</Button>
                <Button sx={{ ...saveradius, width: "125px" }} disabled={loading} disableElevation variant="contained" onClick={handleSubmit}>
                    {
                        loading
                            ? <CircularProgress size={25} thickness={4.5} sx={{ color: "#fff" }} />
                            : "Save & Next"
                    }
                </Button>
            </div>
        </>
    )
}

export default BasicInfo