import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styles from '../subjects/subject.module.css'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { custom, formselect, save } from '../MaterialUI';
import { Exit, Status, Student } from '../../svg';
import { styled } from '@mui/material/styles';

import Api from '../../helper/api';
import * as yup from "yup";
import Toastify from '../../helper/Toastify';
import { useFormik } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';

import { MdEdit } from "react-icons/md";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "none",
  padding: "27px 22px",
  height: "fit-content",
  maxHeight: 500,
  display: "block",
  overflowY: 'auto',
  marginTop: '50px',
  width: '380px',
  "&:focus": {
    outline: "none",
  },
};


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: '60px',
  overflow: 'hidden',
  position: 'absolute',
  top: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const AddStudent = ({ handleClose, open, refresh, setRefresh }) => {
  const toast = new Toastify();
  const api = new Api();

  const [img, setImg] = useState(null)

  // schema --------------------
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Please enter valid email").required("Code is required"),
    status: yup.string().required("Status is required"),
    phone: yup.string().max(10).min(10).required("Phone is required"),
    validity: yup.string().required("Validity is required"),
    type: yup.string().required("Type is required"),
  })


  // formik to handle forms data --------------------
  const {
    errors,
    values,
    handleChange,
    touched,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      status: '',
      phone: "",
      validity: "",
      type: ""
    },
    validationSchema: schema,
    onSubmit: () => {
      AddStudent();
    }
  })

  // add subject -----------------------
  const AddStudent = async () => {
    try {
      let profileImg;
      // upload file
      if (img) {
        const formData = new FormData();
        formData.append("image", img)
        const { data, status } = await api.uploadFile(formData)

        if (status === 200) {
          profileImg = data.data[0]
        }
      }

      const { data, status } = await api.addStudent({ ...values, profileImg })
      if (status === 200) {
        setRefresh(!refresh)
        resetForm();
        handleClose()
        toast.success("Saved Successfully", "Your changes have been saved successfully")
      }
    } catch (err) {
      console.log(err)
      if (err.response) {
        toast.error("Error occured", err.response.data.message)
      }
    }
  }

  const close = () => {
    handleClose();
    resetForm();
    setImg(null)
  }

  const handleFile = async (e) => {
    try {
      let file = e.target.files[0];
      if (file.size > 1000000) {
        toast.error("Error occured", "Please upload file of less than 1 mb")
      } else {
        setImg(file)
      }
    } catch (err) {
      toast.error("Error occured", "File upload error")
    }
  }


  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.editHead}>
          <h2 className={styles.update}>add new student</h2>
          <Exit handleClose={close} />
        </div>
        <form className={styles.form}>
          <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: '-20px' }}>
            {
              img
                ? <>
                  <img src={URL.createObjectURL(img)} style={{ width: "100px", height: "100px", objectFit: "cover", borderRadius: "50%" }} />
                  <label htmlFor="file" style={{ position: 'relative', top: "-30px", right: "-30px" }}>
                    <div style={{ borderRadius: "50%", border: "1px solid #000", width: "25px", height: "25px", display: "flex", alignItems: "center", justifyContent: "center", background: "#000", }}>
                      <MdEdit color='#fff' size={18} />
                    </div>
                    <input type="file" id='file' style={{ display: "none" }} onChange={handleFile} />
                  </label>
                </>
                : <>
                  <label></label>
                  <Button component="label" sx={{ visibility: 'none' }}><Student />
                    <VisuallyHiddenInput type="file" accept="image/png, image/jpeg" onChange={handleFile} />
                  </Button>
                </>
            }
          </span>
          <label>student Name</label>
          <br />
          <div className={styles.input}>
            <input type="text" name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} placeholder='Enter' />
          </div>
          {
            errors.name && touched.name && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-14px" }}>{errors.name}</p>
          }
          <label>email address</label>
          <br />
          <div className={styles.input}>
            <input type="email" name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} placeholder='Enter' />
          </div>
          {
            errors.email && touched.email && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-14px" }}>{errors.email}</p>
          }
          <label>phone number</label>
          <br />
          <div className={styles.input}>
            <input type="number" name="phone" value={values.phone} onChange={handleChange} onBlur={handleBlur} placeholder='Enter' />
          </div>
          {
            errors.phone && touched.phone && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-14px" }}>{errors.phone}</p>
          }
          <label>validity</label>
          <br />
          <div style={{ marginTop: "6px", marginBottom: "21px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker sx={formselect} onChange={(value) => setFieldValue("validity", value.$d)} value={dayjs(values.validity)} />
            </LocalizationProvider>
          </div>
          {
            errors.validity && touched.validity && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-14px" }}>{errors.validity}</p>
          }
          <label>Type</label>
          <br />
          <Select className={styles.formselect}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={formselect}
            name='type'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.type}
            IconComponent={Status}
            displayEmpty
            defaultValue=''
          >
            <MenuItem value="">Type</MenuItem>
            <MenuItem value="STUDENT">Student</MenuItem>
            <MenuItem value="TEACHER">Teacher</MenuItem>
            <MenuItem value="PARENT">Parent</MenuItem>
            <MenuItem value="OTHERS">Others</MenuItem>
          </Select>
          {
            errors.type && touched.type && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-5px" }}>{errors.type}</p>
          }
          <label>Status</label>
          <br />
          <Select className={styles.formselect}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={formselect}
            name='status'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.status}
            IconComponent={Status}
            displayEmpty
            defaultValue=''
          >
            <MenuItem value="">status</MenuItem>
            <MenuItem value="ACTIVE">Active</MenuItem>
            <MenuItem value="INACTIVE">Inactive</MenuItem>
          </Select>
          {
            errors.status && touched.status && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-5px" }}>{errors.status}</p>
          }
          <div className={styles.buttons}>
            <Button sx={custom} disableElevation onClick={close} variant="contained">Cancel</Button>
            <Button sx={save} disableElevation onClick={handleSubmit} variant="contained">save</Button>
          </div>
        </form>
      </Box>
    </Modal>
  )
}

export default AddStudent
