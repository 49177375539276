import React, { useEffect, useMemo, useState } from 'react'
import styles from '../quiz/quiz.module.css'
import { Action, AnswerKey, Darkmenu, DeleteIcon, EditIcon, Info, LightDelete, Lightedit, Menu, Next, Opened, Plusicon, Preview, RightArrow, Search, Status, Table } from '../../svg'
import { Button, MenuItem, Select } from '@mui/material'
import { custom, customradius, formselect, save, saveradius, selectInput } from '../MaterialUI'
import { useNavigate, useParams } from 'react-router-dom'
import Api from '../../helper/api'
import BasicInfo from './BasicInfo'
import QA from './QA'
import FinalPreview from './Preview'


const ViewTest = () => {
    const navigate = useNavigate();
    const api = new Api();
    const params = useParams();

    // states -----------------
    // ------------------------
    const [id, setId] = useState(null);
    const [course, setCourse] = useState({
        subjects: [],
        topics: [],
        chapters: [],
        levels: []
    });
    const [title, setTitle] = useState(3)

    useEffect(() => {
        fetchCourse();
    }, [params.id]);

    const fetchCourse = async () => {
        try {
            const { data, status } = await api.getAllCourse();

            if (status === 200) {
                setCourse(data.data)
                setId(params.id)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handleClick = (id) => {
        setTitle(id)
    }

    const handleCancel = () => {
        navigate('/alltest/all-test')
    }
    return (
        <div className={styles.container}>
            <div className={styles.head}>
                <h1>Add New tests</h1>
                <span>
                    home <RightArrow /> test Management <RightArrow /> <span style={{ color: 'var(--Gray-900, #191B1C)' }}>new test</span>
                </span>
                <div style={{ position: 'absolute', top: '33px', right: '27px' }}>
                    <Button sx={save} onClick={() => navigate('/alltest/all-test')} variant="contained">Back to table</Button>
                </div>
            </div>
            <div className={styles.maincontainer}>
                <div style={{ display: 'flex', gap: '24px' }}>
                    <div onClick={() => handleClick(3)} className={title === 3 ? `${styles.title} ${styles.activeTitle}` : styles.title}>
                        <span>
                            <Preview />preview
                        </span>
                    </div>
                </div>

                {
                    title === 3 &&
                    <FinalPreview
                        showBtn={true}
                        course={course}
                        id={id}
                        handleCancel={handleCancel}
                    />
                }

            </div>
        </div>
    )
}

export default ViewTest
