import axios from "axios";
import FormData from "form-data";


const getToken = async () => {
    try {
        const token = localStorage.getItem("token");
        return token;
    } catch (error) {
        console.error('Error retrieving token:', error);
        return null;
    }
};

const getRefreshToken = async () => {
    try {
        const token = localStorage.getItem("refreshToken");
        return token;
    } catch (error) {
        console.error('Error retrieving Refresh token:', error);
        return null;
    }
};

export default class Api {
    constructor() {
        this.client = null;
        this.api_url = process.env.NODE_ENV === "development" ? "https://backend.physicsfactor.com/api" : `https://backend.physicsfactor.com/api`;
        // this.api_url = process.env.NODE_ENV === "development" ? "https://backend.physicsfactor.com/api" : `https://backend.physicsfactor.com/api`;
    }

    init = (type) => {

        let headers;

        headers = {
            Accept: "application/json",
        }

        if (type === "multipart/form-data") {
            headers = {
                'Content-Type': 'multipart/form-data',
            }
        }


        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000000,
            headers: headers,
        });

        this.client.interceptors.request.use(async (config) => {
            const token = await getToken();

            config.headers["Authorization"] = `Bearer ${token}`
            return config;
        }, (error) => {
            throw error;
        })

        this.client.interceptors.response.use(async (config) => {
            return config
        }, async (error) => {
            let refreshToken = await getRefreshToken();
            console.log("refreshToken", refreshToken)
            const originalRequest = error.config

            if (error.response.status === 401 && originalRequest && !originalRequest._isRetry) {
                originalRequest.isRetry = true

                try {
                    const { data, status } = await axios.post(`${this.api_url}/token/refresh-token`, {
                        "refreshToken": refreshToken
                    })


                    if (status === 200) {
                        localStorage.setItem("token", data.data.token)
                        localStorage.setItem('refreshToken', data.data.refreshToken)
                        originalRequest.headers['Authorization'] = `Bearer ${data.data.token}`

                        return this.client.request(originalRequest)
                    }
                }
                catch (err) {
                    // localStorage.clear();
                    // window.location = "/"
                    console.log(err.message)
                }
            }
            throw error;
        })

        return this.client;
    };


    // login
    login = (data, config) => {
        return this.init().post("/admin/login", data)
    }

    // upload - file 
    uploadFile = (data, config) => {
        return this.init("multipart/form-data").post("/admin/upload-file", data)
    }

    // get all course 
    getAllCourse = (data, config) => {
        return this.init().get("/admin/get-all-course")
    }

    //master data < subjects --------------------
    // -------------------------------------
    subject = (data, config) => {
        return this.init().get(`/admin/subject${config}`, data)
    }

    allSubject = (data) => {
        return this.init().get("/admin/subject")
    }

    getAllTrashedSubject = (data, config) => {
        return this.init().get(`/admin/subject-trashed${config}`, data)
    }

    // add subject
    addSubject = (data, config) => {
        return this.init().post('/admin/subject', data)
    }

    updateSubject = (data, config) => {
        return this.init().put(`/admin/subject/${config?.id}`, data)
    }

    deleteSubject = (data, config) => {
        return this.init().delete(`/admin/subject/${config.id}`, data)
    }

    restoreSubject = (data, config) => {
        return this.init().put(`/admin/subject/${config.id}/restore`, data)
    }

    trashSubject = (data, config) => {
        return this.init().put(`/admin/subject/${config.id}/trash`, data)
    }

    // master data < chapters--------------------
    // ------------------------------------------
    chapter = (data, config) => {
        return this.init().get(`/admin/chapter${config}`, data)
    }

    allTrashedChapter = (data, config) => {
        return this.init().get(`/admin/chapter-trashed${config}`, data);
    }

    allChapter = (data, config) => {
        return this.init().get("/admin/chapter")
    }

    addChapter = (data, config) => {
        return this.init().post(`/admin/chapter`, data)
    }

    updateChapter = (data, config) => {
        return this.init().put(`/admin/chapter/${config?.id}`, data)
    }

    deleteChapter = (data, config) => {
        return this.init().delete(`/admin/chapter/${config.id}`, data)
    }

    restoreChapter = (data, config) => {
        return this.init().put(`/admin/chapter/${config.id}/restore`, data)
    }

    trashChapter = (data, config) => {
        return this.init().put(`/admin/chapter/${config.id}/trash`, data)
    }

    // master data < topics--------------------
    // ------------------------------------------
    topic = (data, config) => {
        return this.init().get(`/admin/topic${config}`, data)
    }

    allTopic = (data, config) => {
        return this.init().get(`/admin/topic`, data)
    }

    allTrashedTopic = (data, config) => {
        return this.init().get(`/admin/topic-trashed${config}`, data);
    }

    addTopic = (data, config) => {
        return this.init().post(`/admin/topic`, data)
    }

    updateTopic = (data, config) => {
        return this.init().put(`/admin/topic/${config?.id}`, data)
    }

    deleteTopic = (data, config) => {
        return this.init().delete(`/admin/topic/${config.id}`, data)
    }

    restoreTopic = (data, config) => {
        return this.init().put(`/admin/topic/${config.id}/restore`, data)
    }

    trashTopic = (data, config) => {
        return this.init().put(`/admin/topic/${config.id}/trash`, data)
    }

    // master data < Levels--------------------
    // ------------------------------------------
    level = (data, config) => {
        return this.init().get(`/admin/level${config}`, data)
    }

    allLevel = (data, config) => {
        return this.init().get(`/admin/level`, data)
    }

    addLevel = (data, config) => {
        return this.init().post(`/admin/level`, data)
    }

    allTrashedLevel = (data, config) => {
        return this.init().get(`/admin/level-trashed${config}`, data);
    }

    updateLevel = (data, config) => {
        return this.init().put(`/admin/level/${config?.id}`, data)
    }

    restoreLevel = (data, config) => {
        return this.init().put(`/admin/level/${config.id}/restore`, data)
    }

    trashLevel = (data, config) => {
        return this.init().put(`/admin/level/${config.id}/trash`, data)
    }

    deleteLevel = (data, config) => {
        return this.init().delete(`/admin/level/${config.id}`, data)
    }

    // Students--------------------
    // ------------------------------------------
    student = (data, config) => {
        return this.init().get(`/admin/student${config}`, data)
    }

    allStudent = (data, config) => {
        return this.init().get(`/admin/student`, data)
    }

    addStudent = (data, config) => {
        return this.init().post(`/admin/student`, data)
    }

    updateStudent = (data, config) => {
        return this.init().put(`/admin/student/${config?.id}`, data)
    }

    deleteStudent = (data, config) => {
        return this.init().delete(`/admin/student/${config.id}`, data)
    }

    // tests -----------
    addTest = (data, config) => {
        return this.init().post(`/admin/test`, data)
    }

    updateTest = (data, config) => {
        return this.init().put(`/admin/test/${config.id}`, data)
    }

    getTestById = (data, config) => {
        return this.init().get(`/admin/test/${config.id}`)
    }

    getAllTest = (data, config) => {
        return this.init().get(`/admin/test${config}`)
    }

    deleteTest = (data, config) => {
        return this.init().delete(`/admin/test/${config.id}`)
    }

    // Quiz -----------
    addQuiz = (data, config) => {
        return this.init().post(`/admin/quiz`, data)
    }

    updateQuiz = (data, config) => {
        return this.init().put(`/admin/quiz/${config.id}`, data)
    }

    getQuizById = (data, config) => {
        return this.init().get(`/admin/quiz/${config.id}`)
    }

    getAllQuiz = (data, config) => {
        return this.init().get(`/admin/quiz${config}`)
    }

    deleteQuiz = (data, config) => {
        return this.init().delete(`/admin/quiz/${config.id}`)
    }

    // plans
    getPlans = (data, config) => {
        return this.init().get("/admin/plans", data)
    }

    // update student plan
    updateStudentPlan = (data, config) => {
        return this.init().put(`/admin/plans/student/${config.id}`, data)
    }

    // subscribers
    getAllSubscribers = (data, config) => {
        return this.init().get(`/admin/subscribers${config}`, data)
    }

}