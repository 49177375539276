import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styles from './Editor.module.css'
import { customradius, saveradius } from '../../pages/MaterialUI';
import Slider from '@mui/material/Slider';
import { CircularProgress } from '@mui/material';

const QualityStyle = {
    position: "absolute",
    top: "50%",
    left: "52%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    border: "none",
    padding: "16px 27px",
    height: "fit-content",
    display: "block",
    width: '250px',
    "&:focus": {
        outline: "none",
    },
};

export const QualityControl = ({ handleSubmit, value, setValue, open, setOpen, loading }) => {
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <Modal
            open={open}>
            <Box sx={QualityStyle}>
                <Slider aria-label="Volume" value={value} onChange={(e) => setValue(e.target.value)} sx={{ color: "#000" }} />
                <p>Compress {value} %</p>
                <div className={styles.buttons} style={{ justifyContent: 'space-between', marginTop: 30 }}>
                    <Button sx={customradius} disableElevation onClick={handleClose} variant="contained">Cancel</Button>
                    <Button sx={{ ...saveradius, width: "125px" }} disableElevation variant="contained" onClick={handleSubmit}>
                        {
                            loading
                                ? <CircularProgress size={25} thickness={4.5} sx={{ color: "#fff" }} />
                                : "Save & Next"
                        }
                    </Button>
                </div>
            </Box>
        </Modal>
    )
}