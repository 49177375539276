function daysRemaining(targetDate) {
    // Get current date and time
    const currentDate = new Date();

    // Get target date and time
    const targetDateTime = new Date(targetDate);

    // Calculate the difference in milliseconds
    const differenceMs = targetDateTime - currentDate;

    // Convert milliseconds to days

    let daysRemaining = 0
    if (differenceMs > 0) {
        daysRemaining = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
    }

    return daysRemaining;
}

export default daysRemaining