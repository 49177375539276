import React from 'react'
import styles from './header.module.css'
import { useNavigate } from 'react-router-dom'
import { ArrowDown } from '../../svg';
import Toastify from '../../helper/Toastify';

const Header = () => {
  const navigate = useNavigate();
  const toast = new Toastify();

  const handleLogout = () => {
    localStorage.clear();

    toast.success("Logged out successfully")
    setTimeout(() => {
      window.location = "/"
    }, 1500)
  }

  return (
    <div className={styles.header}>
      <h2>logo</h2>
      <div className={styles.profile}>
        <button className={styles.button} onClick={handleLogout}>Logout</button>
        <img src="/assets/Avatar.png" alt="" />
        <ArrowDown />
      </div>
    </div>
  )
}

export default Header
