import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styles from './style.module.css';
import { custom, formselect, save } from '../../pages/MaterialUI';
import { Exit, Status } from '../../svg';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    border: "none",
    padding: "27px 22px",
    height: "fit-content",
    display: "block",
    width: '372px',
    "&:focus": {
        outline: "none",
    },
};

const Delete = ({ handleClose, open, handleDelete }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className={styles.editHead}>
                    <h2 className={styles.update}>delete!</h2>
                    <Exit handleClose={handleClose} />
                </div>
                <form className={styles.form}>
                    <label style={{ color: '#666' }}>Are you sure, you want to delete this data?</label>
                    <br />

                    <div className={styles.buttons}>
                        <Button sx={custom} disableElevation onClick={handleClose} variant="contained">Cancel</Button>
                        <Button sx={save} disableElevation variant="contained" onClick={handleDelete}>Delete</Button>
                    </div>
                </form>
            </Box>
        </Modal>
    )
}

export default Delete
