import React, { useEffect } from 'react'
import styles from './login.module.css'
import { Email, Pass } from '../../svg'
import { useNavigate } from 'react-router-dom'

import { useFormik } from "formik"
import * as yup from "yup";
import Api from '../../helper/api';
import Toastify from '../../helper/Toastify'

const Login = () => {
  const api = new Api();
  const navigate = useNavigate();
  const toast = new Toastify();

  // schema -----------
  const schema = yup.object().shape({
    email: yup.string().matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Please enter valid email").required("Please enter valid email"),
    password: yup.string().required("Password is required")
  })

  const {
    errors, values, handleChange, touched, setFieldValue, handleBlur, resetForm, handleSubmit,
  } = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: schema,
    onSubmit: () => {
      handleLogin();
    }
  })

  // login
  const handleLogin = async () => {
    try {
      const { data, status } = await api.login(values)
      if (status === 200) {
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("refreshToken", data.data.refreshToken);
        toast.success("Login Success", "You are successfully logged in")
        setTimeout(() => {
          navigate("/master/subjects")
        }, 2000);
      }

    } catch (err) {
      if (err.response) {
        toast.error("Error Occured", err.response.data.message)
      }
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.logo}>Logo</div>
      <div className={styles.main}>
        <img src="/assets/Photo.png" />
        <div className={styles.login}>
          <div className={styles.content}>
            <h2>Welcome!</h2>
            <p>Login to your account </p>
            <div className={styles.inputbox}>
              <Email />
              <input type="email" onBlur={handleBlur} value={values.email} placeholder='Email Address' name="email" onChange={handleChange} />
            </div>
            {
              errors.email && touched.email && <p style={{ color: "red", fontSize: "12px" }}>{errors.email}</p>
            }
            <br />
            <div className={styles.inputbox}>
              <Pass />
              <input type="text" placeholder='Password' onBlur={handleBlur} value={values.password} name='password' onChange={handleChange} />
            </div>
            {
              errors.password && touched.password && <p style={{ color: "red", fontSize: "12px" }}>{errors.password}</p>
            }
            <br />
            <button onClick={handleSubmit}>Login</button>
            {/* <a href="#">Forget Password?</a> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
