import React, { useEffect, useState } from 'react'
import styles from './sidebar.module.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { Dashboard, DropUp, Master, QA } from '../../svg';

const Sidebar = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [path, setPath] = useState('');
  const [subpath, setSubpath] = useState('');
  const pathname = location.pathname.split('/')[1];
  const subpathname = location.pathname.split('/')[2];
  useEffect(() => {
    setPath(pathname)
    setSubpath(subpathname)
  }, [pathname, subpathname])
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div>
          <div onClick={() => navigate('/dashboard')} className={path === 'dashboard' ? `${styles.menuActive} ${styles.menu}` : `${styles.menu}`}>
            <Dashboard />
            Dashboard
          </div>
          <div onClick={() => navigate('/master')} className={path === 'master' ? `${styles.menuActive} ${styles.menu}` : `${styles.menu}`}>
            <Master />
            Master Data
            <DropUp id={path === 'master'} />
          </div>
          {
            path === 'master' ?
              <div className={styles.subpaths}>
                <div onClick={() => navigate('master/subjects')} className={subpath === 'subjects' ? styles.submenu : ''}>Subjects</div>
                <div onClick={() => navigate('master/chapters')} className={subpath === 'chapters' ? styles.submenu : ''}>Chapters</div>
                <div onClick={() => navigate('master/topics')} className={subpath === 'topics' ? styles.submenu : ''}>Topics</div>
                <div onClick={() => navigate('master/levels')} className={subpath === 'levels' ? styles.submenu : ''}>Levels</div>
              </div> : ''
          }
          <div onClick={() => navigate('/trash')} className={path === 'trash' ? `${styles.menuActive} ${styles.menu}` : `${styles.menu}`}>
            <Master />
            Trash
            <DropUp id={path === 'trash'} />
          </div>
          {
            path === 'trash' ?
              <div className={styles.subpaths}>
                <div onClick={() => navigate('trash/subjects')} className={subpath === 'subjects' ? styles.submenu : ''}>Subjects</div>
                <div onClick={() => navigate('trash/chapters')} className={subpath === 'chapters' ? styles.submenu : ''}>Chapters</div>
                <div onClick={() => navigate('trash/topics')} className={subpath === 'topics' ? styles.submenu : ''}>Topics</div>
                <div onClick={() => navigate('trash/levels')} className={subpath === 'levels' ? styles.submenu : ''}>Levels</div>
              </div> : ''
          }
          <div onClick={() => navigate('/qa')} className={path === 'qa' ? `${styles.menuActive} ${styles.menu}` : `${styles.menu}`}>
            <QA />
            Q&A Management
            <DropUp id={path === 'qa'} />
          </div>
          {
            path === 'qa' ?
              <div className={styles.subpaths}>
                <div onClick={() => navigate('qa/quiz')} className={subpath === 'quiz' ? styles.submenu : ''}>Quizz</div>
              </div> : ''
          }
          <div onClick={() => navigate('/alltest')} className={path === 'alltest' ? `${styles.menuActive} ${styles.menu}` : `${styles.menu}`}>
            <QA />
            Test Management
            <DropUp id={path === 'alltest'} />
          </div>
          {
            path === 'alltest' ?
              <div className={styles.subpaths}>
                <div onClick={() => navigate('alltest/all-test')} className={subpath === 'all-test' ? styles.submenu : ''}>tests</div>
              </div> : ''
          }
          <div onClick={() => navigate('/users')} className={path === 'users' ? `${styles.menuActive} ${styles.menu}` : `${styles.menu}`}>
            <QA />
            Users
            <DropUp id={path === 'users'} />
          </div>
          {
            path === 'users' ?
              <div className={styles.subpaths}>
                <div onClick={() => navigate('users/students')} className={subpath === 'students' ? styles.submenu : ''}>students</div>
                <div onClick={() => navigate('users/subscribers')} className={subpath === 'subscribers' ? styles.submenu : ''}>Subscribers</div>
              </div>
              : ''
          }
        </div>
      </div>
      <div className={styles.right}>
        {children}
      </div>
    </div>
  )
}

export default Sidebar
