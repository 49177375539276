import styles from '../subjects/subject.module.css'
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { custom, formselect, save } from '../MaterialUI';
import { Exit, Status } from '../../svg';
import * as yup from "yup";
import Selects from 'react-select'

import { useFormik } from 'formik';
import Api from '../../helper/api';
import Toastify from '../../helper/Toastify';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "none",
  padding: "27px 22px",
  height: "fit-content",
  display: "block",
  width: '380px',
  "&:focus": {
    outline: "none",
  },
};
const EditChapter = ({ open, handleClose, chapterToEdit, refresh, setRefresh, subjects }) => {
  const api = new Api();
  const toast = new Toastify();
  const [sub, setSub] = useState('');

  // schema --------------------
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    code: yup.string().required("Code is required"),
    status: yup.string().required("Status is required"),
    subject: yup.string().required("Subject is required")
  })


  // formik to handle forms data --------------------
  const {
    errors,
    values,
    handleChange,
    touched,
    handleBlur,
    setValues,
    handleSubmit,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      name: "",
      code: "",
      status: '',
      subject: ""
    },
    validationSchema: schema,
    onSubmit: () => {
      updateChapter();
    }
  })

  useEffect(() => {
    setValues({
      ...chapterToEdit,
      subject: chapterToEdit?.subject?._id
    })

    if (chapterToEdit?.subject) {
      setSub({
        value: chapterToEdit?.subject?._id,
        label: chapterToEdit?.subject?.name
      })
    }
  }, [chapterToEdit, open])

  // update chapter
  const updateChapter = async () => {
    try {
      const { data, status } = await api.updateChapter(values, {
        id: chapterToEdit._id
      })
      if (status === 200) {
        setRefresh(!refresh)
        resetForm();
        setSub("")
        toast.success("Updated Successfully", "Your changes have been updated successfully")
        handleClose();
      }
    } catch (err) {
      if (err.response) {
        toast.error("Error occured", err.response.data.message)
      }
    }
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.editHead}>
          <h2 className={styles.update}>Edit Chapter</h2>
          <Exit handleClose={handleClose} />
        </div>
        <form className={styles.form}>
          <label>Subject Name</label>
          <br />
          <div style={{ marginBottom: "21px", marginTop: "6px" }}>
            <Selects
              options={subjects.map((subject) => {
                return {
                  value: subject._id,
                  label: subject.name
                }
              })}
              onChange={(e) => {
                setSub(e)
                setFieldValue("subject", e.value)
              }}
              name='subject'
              value={sub}
              styles={{
                input: (styles) => ({ ...styles, fontSize: "14px", color: "#8C94A3 !important" }),
                container: (styles) => ({ ...styles, width: "100%" }),
                control: () => ({ display: "flex", border: "1px solid #E9EAF0", borderRadius: "4px", outline: "none", padding: 3, color: "#8C94A3 !important" }),
                placeholder: (styles) => ({ ...styles, fontSize: 14, color: "#8C94A3" }),

              }}
            />
          </div>
          {
            errors.subject && touched.subject && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-14px" }}>{errors.subject}</p>
          }
          <label>Chapter Name</label>
          <br />
          <div className={styles.input}>
            <input type="text" placeholder='Enter' name='name' value={values.name} onChange={handleChange} onBlur={handleBlur} />
          </div>
          {
            errors.name && touched.name && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-14px" }}>{errors.name}</p>
          }
          <label>Code</label>
          <br />
          <div className={styles.input}>
            <input type="text" name='code' value={values.code} onChange={handleChange} onBlur={handleBlur} placeholder='Enter' />
          </div>
          {
            errors.code && touched.code && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-14px" }}>{errors.code}</p>
          }
          <label>Status</label>
          <br />
          <Select className={styles.formselect}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={formselect}
            onChange={handleChange}
            onBlur={handleBlur}
            name='status'
            value={values.status}
            IconComponent={Status}
            displayEmpty
            defaultValue=''
          >
            <MenuItem value="">status</MenuItem>
            <MenuItem value="ACTIVE">Active</MenuItem>
            <MenuItem value="INACTIVE">Inactive</MenuItem>
          </Select>
          {
            errors.status && touched.status && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-5px" }}>{errors.status}</p>
          }

          <div className={styles.buttons}>
            <Button sx={custom} disableElevation onClick={handleClose} variant="contained">Cancel</Button>
            <Button sx={save} onClick={handleSubmit} disableElevation variant="contained">Update</Button>
          </div>
        </form>
      </Box>
    </Modal>
  )
}

export default EditChapter
