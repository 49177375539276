import React, { createRef, useEffect, useMemo, useRef } from 'react'
import Api from '../../helper/api'
import Toastify from '../../helper/Toastify';


/// reac quill
import ReactQuill, { Quill } from 'react-quill'
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import 'quill/dist/quill.snow.css'

// katex
import katex from 'katex';
import "katex/dist/katex.min.css";

// import jquery 
import "../../shared/MathQuill/jquery"

// MathQuill dependency
import "@edtr-io/mathquill/build/mathquill.js";
import "@edtr-io/mathquill/build/mathquill.css";

// mathquill4quill include
import mathquill4quill from "../../shared/MathQuill/mathquill4quill";
import "../../shared/MathQuill/mathquill4quill.css";


import ImageResize from 'quill-image-resize-module-react';
import BlotFormatter from 'quill-blot-formatter';

// custom operators
import { CUSTOM_OPERATORS } from '../../helper/quill'
import CustomImage from '../CustomImage';
import * as MathType from "@wiris/mathtype-generic";
let Font = Quill.import('attributors/style/font');
Font.whitelist = ['Ubuntu', 'Raleway', 'Roboto', "Serif"];


Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register("formats/image", CustomImage);
Quill.register(Font, true);
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)


window.katex = katex;

const EditorQuill = ({ handleChange, questions, toOpen }) => {
    const api = new Api();
    const toast = new Toastify();
    const macros = useRef(null)


    const imageHandler = async () => {
        const input = document.createElement('input')

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            let file = input.files[0];

            try {
                if (file.size > 1000000) {
                    toast.error("Error occured", "Please upload file of less than 1 mb")
                } else {
                    const formData = new FormData();
                    formData.append("image", file)
                    const { data, status } = await api.uploadFile(formData);

                    const range = macros.current.getEditorSelection();
                    var res = data.data[0];

                    macros.current.getEditor().insertEmbed(range.index, 'image', res);
                }
            } catch (err) {
                console.log(err)
                toast.error("Error occured", "File upload error")
            }
        };
    }

    const pasteImageHandler = async (imageDataUrl, type, imageData) => {
        try {
            const blob = imageData.toBlob()
            const file = imageData.toFile()

            async function fetchUrl(file) {
                try {
                    toast.success("", "Uploading image")

                    // generate a form data
                    const formData = new FormData();
                    formData.append("image", file)
                    const { data, status } = await api.uploadFile(formData);

                    const json = data.data[0]
                    toast.success("", "Image uploaded")
                    return json;
                } catch (err) {
                    toast.warning("", "Error uploading Image")
                }
            }

            const url = await fetchUrl(file);

            const range = macros.current.selection;
            const index = range?.index

            macros.current.getEditor().insertEmbed(index, 'image', url);

        } catch (errr) {
            console.log(errr)
        }
    }

    const modules = useMemo(() => (
        {
            formula: true,
            toolbar: {
                container: [
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }, { 'font': Font.whitelist }],
                    [{ size: ["small", false, "large", "huge"] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                        { align: [] }
                    ],
                    ['link', 'image'],
                    [{ 'align': [] }],
                    [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
                ],
                'handlers': {
                    image: imageHandler
                },
            },
            imageResize: {
                parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize']
            },
            imageDropAndPaste: {
                // add an custom image handler
                handler: pasteImageHandler,
            },
            blotFormatter: {},
        }
    ), [])

    var formats = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "image", "align", "size", "formula", "width", "style"
    ];

    useEffect(() => {
        const ToolBarRef = document.getElementsByClassName("ql-toolbar")[0]
        if (ToolBarRef) {
            console.log("runs again")
            const genericIntegrationInstance = new window.WirisPlugin.GenericIntegration({
                target: document.getElementsByClassName("ql-editor")[0],
                toolbar: ToolBarRef
            });

            genericIntegrationInstance.init();
            genericIntegrationInstance.listeners.fire("onTargetReady", {});
        }
    }, [])
    return (
        <>
            <ReactQuill
                theme="snow"
                ref={macros}
                modules={modules}
                formats={formats}
                placeholder="write your content ...."
                onChange={handleChange}
                value={questions[toOpen.index][toOpen.title]?.html}
                style={{
                    height: "380px",
                    backgroundColor: 'rgba(227, 241, 254, 0.4)',
                    '&.ql-editor': {
                        border: 'none !important',
                        backgroundColor: 'rgba(227, 241, 254, 0.4)',
                    },
                    '&.ql-toolbar.ql-snow': {
                        border: 'none',
                    },
                }}
            >
            </ReactQuill>
        </>
    )
}

export default EditorQuill