import React, { useEffect, useMemo, useState } from 'react'
import styles from '../quiz/quiz.module.css'
import { Action, AnswerKey, ContentDropdown, DarkDelete, Darkmenu, DeleteIcon, DeleteIconLight, EditIcon, Info, LightDelete, Lightedit, MenuBar, Next, Opened, Plusicon, Preview, RightArrow, Search, Status, Table } from '../../svg'
import { Button, Select } from '@mui/material'
import { custom, customradius, formselect, save, saveradius, selectInput } from '../MaterialUI'
import { useNavigate } from 'react-router-dom'
import Api from '../../helper/api'
import { MenuButton, Dropdown, Menu, MenuItem } from '@mui/joy'
import Editor from '../../shared/Editor/Editor'
import Toastify from '../../helper/Toastify'
import { CircularProgress } from '@mui/material'
import Delete from "../../shared/Delete/Delete"
import imageCompression from 'browser-image-compression';
import { QualityControl } from '../../shared/Editor/QualityControl'


const QA = ({ id, setTitle, handleCancel }) => {
    const navigate = useNavigate();
    const api = new Api();
    const toast = new Toastify();

    // states -----------------
    // ------------------------
    const [loading, setLoading] = useState(false)
    const [qualityLoading, setQualityLoading] = useState(false);
    const [openQuality, setQuality] = useState(false)
    const [tempImg, setTempImg] = useState([]);

    // img compress value
    const [value, setValue] = useState(10)
    const [open, setOpen] = useState(false);
    const [toOpen, setToOpen] = useState({
        title: "",
        index: 0
    });
    const [deleteQue, setDeleteQue] = useState({
        open: false,
        index: null
    })
    const [openDlt, setOpenDlt] = useState(false);
    const [toDelete, setToDelete] = useState({
        title: "",
        index: 0
    })

    const [questions, setQuestions] = useState([
        {
            que: {},
            ans: {},
            sol: {}
        }
    ])

    // run useEffect and save all the fields here
    useEffect(() => {
        id && getTest();
    }, [id])

    const getTest = async () => {
        try {
            const { data, status } = await api.getTestById("", { id })

            if (status === 200) {
                if (data.data.questions.length > 0) {
                    setQuestions(data.data.questions)
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    //  ------add new question -------------------
    // ------------------------------------------
    const addNewQue = () => {
        setQuestions((prev) => {
            return [...prev, {
                que: {},
                ans: {},
                sol: {}
            }]
        })
    }

    const handleDelete = () => {
        setQuestions((prev) => {
            const cloned = [...prev]
            cloned[toDelete.index][toDelete.title] = {}
            return [...cloned]
        })
        handleCloseDlt();
    }

    // --------remove question------------------
    // ---------------------------------------
    const handleRemoveQue = () => {
        const cloneQue = [...questions]
        cloneQue.splice(deleteQue.index, 1)
        setQuestions((prev) => {
            return [...cloneQue]
        })
        setDeleteQue({ open: false, index: null })
    }

    const handleOpen = (title, index) => {
        setOpen(true)
        setToOpen({
            title,
            index
        })
    }

    const handleOpenDlt = (title, index) => {
        setOpenDlt(true)
        setToDelete({
            title,
            index
        })
    }

    const handleClose = () => {
        setOpen(false)
        setToOpen({
            title: "",
            index: 0
        })
    }

    const handleCloseDlt = () => {
        setOpenDlt(false)
        setToDelete({
            title: "",
            index: 0
        })
    }

    // save -----------------------
    const saveQuestions = async () => {
        try {
            setLoading(true)
            const { data, status } = await api.updateTest({ questions, state: 2 }, { id })
            if (status === 200) {
                toast.success("Saved Successfully", "Your changes have been saved successfully")
                setLoading(false)
                setTitle(3)
            }
        } catch (err) {
            if (err.response) {
                toast.error("Error occured", err.response.data.message)
            }
            setLoading(false)
        }
    }

    const handleBulkUpload = async (e) => {
        try {
            let files = e.target.files;
            console.log("files", files)
            setQuality(true);
            setTempImg(files)
        } catch (err) {
            toast.error("Error occured", "File upload error")
        }
    }

    const handleQuality = async () => {
        const options = {
            maxSizeMB: 3,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            initialQuality: (100 - value) / 100
        }


        try {
            setQualityLoading(true);
            // compress images
            for (let i = 0; i < tempImg.length; i++) {
                const compressedFile = await imageCompression(tempImg[i], options);

                try {
                    const formData = new FormData();
                    formData.append("image", compressedFile)

                    const { data, status } = await api.uploadFile(formData)

                    if (status === 200) {
                        const fileName = compressedFile.name.split(".")[0];
                        const indexToUpdate = fileName.split("-")[fileName.split("-").length - 1]
                        const type = fileName.split("-")[fileName.split("-").length - 2]
                        let title;

                        if (type === "A") {
                            title = "ans"
                        } else if (type === "Q") {
                            title = "que"
                        } else {
                            title = "sol"
                        }

                        setQuestions((prev) => {
                            const cloned = [...prev]
                            const isExist = cloned[indexToUpdate - 1]
                            if (isExist) {

                                cloned[indexToUpdate - 1][title].img = [{
                                    src: data.data[0]
                                }]
                                return [...cloned]
                            } else {
                                const ques = {
                                    que: {},
                                    ans: {},
                                    sol: {},
                                    [title]: {
                                        img: [{
                                            src: data.data[0]
                                        }]
                                    }
                                }

                                return [...cloned, ques]
                            }
                        })
                    }
                } catch (err) {
                    failed++
                }
            }

            let failed = 0;

            if (failed > 0) {
                toast.warning("Warning", `${failed} images failed to save`)
            }
            setQuality(false);
            setQualityLoading(false);
        } catch (err) {
            console.log(err)
            setQuality(false);
            setQualityLoading(false);
        }
    }
    return (
        <>
            {
                open &&
                <Editor handleClose={handleClose} open={open} toOpen={toOpen} questions={questions} setQuestions={setQuestions} />
            }
            <QualityControl value={value} setValue={setValue} setOpen={setQuality} open={openQuality} handleSubmit={handleQuality} loading={qualityLoading} />

            <Delete handleClose={handleCloseDlt} open={openDlt} handleDelete={handleDelete} />
            <Delete handleClose={() => setDeleteQue({ open: false, index: null })} open={deleteQue.open} handleDelete={handleRemoveQue} />
            <div className={styles.headerTitle}><span>Q & A <small style={{ color: '#007AFF', fontSize: '14px' }}>{`(${questions.length})`}</small></span>
                <Button sx={customradius} component="label" disableElevation variant="contained">
                    bulk upload
                    <input type="file" name="bulkUpload" id="bulkUpload" onChange={handleBulkUpload} multiple accept='image/*' style={{ display: "none" }} />
                </Button>
            </div>
            <div className={styles.question}>
                <div className={styles.queslist}>
                    {
                        questions.map((que, i) => {
                            return (
                                <div className={styles.que}>
                                    <div className={styles.tab} style={{ margin: '8px 0', background: 'var(--Gray-50, #F5F7FA)' }}>
                                        <span style={{ fontSize: '16px', fontWeight: '500', color: 'var(--Gray-900, #1D2026)' }}>
                                            <Darkmenu /> Question {i + 1}:
                                        </span>
                                        <span>
                                            {
                                                questions.length > 1
                                                    ? <DarkDelete handleDelete={() => setDeleteQue({ open: true, index: i })} />
                                                    : <LightDelete />
                                            }
                                        </span>
                                    </div>
                                    <div className={styles.tab}>
                                        <span><MenuBar /> Add question</span>
                                        <span>
                                            <Dropdown>
                                                <MenuButton style={{ border: 'none', padding: '0' }}>
                                                    <div className={styles.img}>Contents <ContentDropdown /></div>
                                                </MenuButton>
                                                <Menu>
                                                    <MenuItem sx={{ fontSize: 14 }} onClick={() => handleOpen("que", i)}>Attach Image/note</MenuItem>
                                                </Menu>
                                            </Dropdown>

                                            {
                                                questions[i].que.html || questions[i].que.img ? <EditIcon onClick={() => handleOpen("que", i)} /> : <Lightedit />
                                            }
                                            {
                                                questions[i].que.html || questions[i].que.img ? <DeleteIcon onClick={() => handleOpenDlt("que", i)} /> : <DeleteIconLight />
                                            }
                                        </span>
                                    </div>
                                    <div className={styles.tab}>
                                        <span><MenuBar /> Add answer key</span>
                                        <span>
                                            <Dropdown>
                                                <MenuButton style={{ border: 'none', padding: '0' }}>
                                                    <div className={styles.img}>Contents <ContentDropdown /></div>
                                                </MenuButton>
                                                <Menu>
                                                    <MenuItem sx={{ fontSize: 14 }} onClick={() => handleOpen("ans", i)}>Attach Image/note</MenuItem>
                                                </Menu>
                                            </Dropdown>
                                            {
                                                questions[i].ans.html || questions[i].ans.img ? <EditIcon onClick={() => handleOpen("ans", i)} /> : <Lightedit />
                                            }
                                            {
                                                questions[i].ans.html || questions[i].ans.img ? <DeleteIcon onClick={() => handleOpenDlt("ans", i)} /> : <DeleteIconLight />
                                            }
                                        </span>
                                    </div>
                                    <div className={styles.tab}>
                                        <span><MenuBar /> Add solution</span>
                                        <span>
                                            <Dropdown>
                                                <MenuButton style={{ border: 'none', padding: '0' }}>
                                                    <div className={styles.img}>Contents <ContentDropdown /></div>
                                                </MenuButton>
                                                <Menu>
                                                    <MenuItem sx={{ fontSize: 14 }} onClick={() => handleOpen("sol", i)}>Attach Image/note</MenuItem>
                                                </Menu>
                                            </Dropdown>
                                            {
                                                questions[i].sol.html || questions[i].sol.img ? <EditIcon onClick={() => handleOpen("sol", i)} /> : <Lightedit />
                                            }
                                            {
                                                questions[i].sol.html || questions[i].sol.img ? <DeleteIcon onClick={() => handleOpenDlt("sol", i)} /> : <DeleteIconLight />
                                            }
                                        </span>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <button className={styles.addque} onClick={addNewQue}>Add New Question</button>
                    <div className={styles.buttonquiz}>
                        <Button sx={customradius} onClick={() => setTitle(1)} disableElevation variant="contained">Cancel</Button>
                        <Button sx={{ ...saveradius, width: "125px" }} disabled={loading} disableElevation variant="contained" onClick={saveQuestions}>
                            {
                                loading
                                    ? <CircularProgress size={25} thickness={4.5} sx={{ color: "#fff" }} />
                                    : "Save & Next"
                            }
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QA