import React, { useEffect, useMemo, useState } from 'react'
import styles from '../quiz/quiz.module.css'
import { Action, AnswerKey, Darkmenu, DeleteIcon, EditIcon, Info, LightDelete, Lightedit, Menu, Next, Opened, Plusicon, Preview, RightArrow, Search, Status, Table } from '../../svg'
import { Button, CircularProgress, MenuItem, Select } from '@mui/material'
import { custom, customradius, formselect, save, saveradius, selectInput } from '../MaterialUI'
import { useNavigate } from 'react-router-dom'
import Api from '../../helper/api'
import Selects from 'react-select'
import { useFormik } from 'formik'
import * as yup from "yup";
import Toastify from '../../helper/Toastify'


const BasicInfo = ({ course, id, setId, setTitle, handleCancel }) => {
    const api = new Api();
    const toast = new Toastify();

    const [courseValue, setCourseValue] = useState({
        subject: "",
        chapter: "",
        level: ""
    });
    const [loading, setLoading] = useState(false);

    // schema --------------------
    const schema = yup.object().shape({
        subject: yup.string().required("Subject is required"),
        chapter: yup.string().required("Chapter is required"),
        level: yup.string().required("Level is required"),
        status: yup.string().required("Status is required"),
    })

    // formik to handle forms data --------------------
    const {
        errors,
        values,
        handleChange,
        touched,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm
    } = useFormik({
        initialValues: {
            subject: "",
            chapter: "",
            status: '',
            level: ''
        },
        validationSchema: schema,
        onSubmit: () => {
            id ? updateBasicInfo() : addBasicInfo()
        }
    })

    // add subject -----------------------
    const addBasicInfo = async () => {
        try {
            setLoading(true)
            const { data, status } = await api.addTest({ ...values, state: 1 })
            if (status === 200) {
                toast.success("Saved Successfully", "Your changes have been saved successfully")
                setId(data.data._id)
                setLoading(false)
                setTitle(2)
            }
        } catch (err) {
            if (err.response) {
                toast.error("Error occured", err.response.data.message)
            }
            setLoading(false)
        }
    }

    // add subject -----------------------
    const updateBasicInfo = async () => {
        try {
            setLoading(true)
            const { data, status } = await api.updateTest({ ...values, state: 1 }, { id })
            if (status === 200) {
                toast.success("Saved Successfully", "Your changes have been saved successfully")
                setLoading(false)
                setTitle(2)
            }
        } catch (err) {
            if (err.response) {
                toast.error("Error occured", err.response.data.message)
            }
            setLoading(false)
        }
    }

    const handleValues = (prop, value) => {
        setCourseValue((prev) => {
            return { ...prev, [prop]: value }
        })
        setFieldValue(prop, value.value)
    }

    useEffect(() => {
        const chapter = course.chapters.find((chapter) => chapter._id === courseValue.chapter.value)

        if (course?.chapters?.length > 0) {
            if (chapter?.subject !== courseValue.subject.value) {
                handleValues("chapter", "")
            }
        }

    }, [courseValue.subject.value]);

    // run useEffect and save all the fields here
    useEffect(() => {
        id && getTest();
    }, [id])


    const getTest = async () => {
        try {
            const { data, status } = await api.getTestById("", { id })

            if (status === 200) {
                setValues({
                    subject: data.data.subject,
                    chapter: data.data.chapter,
                    level: data.data.level,
                    status: data.data.status
                })

                const subject = course.subjects.find(subject => subject._id === data.data.subject)
                const chapter = course.chapters.find(chapter => chapter._id === data.data.chapter)
                const level = course.levels.find(level => level._id === data.data.level)

                setCourseValue({
                    subject: { value: subject._id, label: subject.name },
                    chapter: { value: chapter._id, label: chapter.name },
                    level: { value: level._id, label: level.name }
                })
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className={styles.headerTitle}>basic information</div>
            <div className={styles.inputboxes}>
                <span>
                    <label>course subject</label><br />
                    <Selects
                        options={course.subjects.map((subject) => {
                            return {
                                value: subject._id,
                                label: subject.name
                            }
                        })}
                        onChange={(e) => handleValues("subject", e)}
                        name='subject'
                        onBlur={handleBlur}
                        value={courseValue.subject}
                        styles={{
                            input: (styles) => ({ ...styles, fontSize: "5px", color: "#8C94A3 !important" }),
                            container: (styles) => ({ ...styles, width: "100%", marginTop: "6px" }),
                            control: () => ({ display: "flex", border: "1px solid #E9EAF0", borderRadius: "4px", outline: "none", padding: 4, color: "#8C94A3 !important" }),
                            placeholder: (styles) => ({ ...styles, fontSize: 14, color: "#8C94A3" }),

                        }}
                    />
                    {
                        errors.subject && touched.subject && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-5px" }}>{errors.subject}</p>
                    }
                </span>
                <span>
                    <label>subject chapter</label><br />
                    <Selects
                        options={course.chapters.filter((chapter) => chapter.subject.includes(courseValue.subject.value || "")).map((chapter) => {
                            return {
                                value: chapter._id,
                                label: chapter.name
                            }
                        })}
                        onChange={(e) => handleValues("chapter", e)}
                        name='chapter'
                        onBlur={handleBlur}
                        value={courseValue.chapter}
                        styles={{
                            input: (styles) => ({ ...styles, fontSize: "5px", color: "#8C94A3 !important" }),
                            container: (styles) => ({ ...styles, width: "100%", marginTop: "6px" }),
                            control: () => ({ display: "flex", border: "1px solid #E9EAF0", borderRadius: "4px", outline: "none", padding: 4, color: "#8C94A3 !important" }),
                            placeholder: (styles) => ({ ...styles, fontSize: 14, color: "#8C94A3" }),

                        }}
                    />
                    {
                        errors.chapter && touched.chapter && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-5px" }}>{errors.chapter}</p>
                    }
                </span>
                <br />
                <span>
                    <label>course level</label><br />
                    <Selects
                        options={course.levels.map((level) => {
                            return {
                                value: level._id,
                                label: level.name
                            }
                        })}
                        onChange={(e) => handleValues("level", e)}
                        name='level'
                        onBlur={handleBlur}
                        value={courseValue.level}
                        styles={{
                            input: (styles) => ({ ...styles, fontSize: "5px", color: "#8C94A3 !important" }),
                            container: (styles) => ({ ...styles, width: "100%", marginTop: "6px" }),
                            control: () => ({ display: "flex", border: "1px solid #E9EAF0", borderRadius: "4px", outline: "none", padding: 4, color: "#8C94A3 !important" }),
                            placeholder: (styles) => ({ ...styles, fontSize: 14, color: "#8C94A3" }),

                        }}
                    />
                    {
                        errors.level && touched.level && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-5px" }}>{errors.level}</p>
                    }
                </span>
                <span>
                    <label>status</label><br />
                    <Select className={styles.formselect}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        sx={formselect}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="status"
                        value={values.status}
                        IconComponent={Status}
                        displayEmpty
                        defaultValue=''
                    >
                        <MenuItem value="">status</MenuItem>
                        <MenuItem value="ACTIVE">Active</MenuItem>
                        <MenuItem value="INACTIVE">Inactive</MenuItem>
                    </Select>
                    {
                        errors.status && touched.status && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-5px" }}>{errors.status}</p>
                    }
                </span>
            </div>
            <div className={styles.buttons}>
                <Button sx={customradius} onClick={handleCancel} disableElevation variant="contained">Cancel</Button>
                <Button sx={{ ...saveradius, width: "125px" }} disabled={loading} disableElevation variant="contained" onClick={handleSubmit}>
                    {
                        loading
                            ? <CircularProgress size={25} thickness={4.5} sx={{ color: "#fff" }} />
                            : "Save & Next"
                    }
                </Button>
            </div>
        </>
    )
}

export default BasicInfo