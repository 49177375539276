import React, { useEffect, useState } from 'react'
import classes from '../quiz/quiz.module.css'
import styles from '../subjects/subject.module.css'
import { Action, Active, Inactive, Left, Next, Right, RightArrow, Search, Status } from '../../svg'
import { Avatar, Button, MenuItem, Select } from '@mui/material'
import { selectInput } from '../MaterialUI'
import AddStudent from './AddStudent'
import DeleteStudent from './DeleteStudent'
import { MenuButton, Dropdown, Menu, MenuItem as MenuAction } from '@mui/joy'
import Api from '../../helper/api';
import Toastify from '../../helper/Toastify';
import Delete from '../../shared/Delete/Delete'
import daysRemaining from '../../helper/daysRemaining'
import EditStudent from './EditStudent'
import UpdatePlan from './UpdatePlan'

const colors = [
  "#2A3D45",
  "#C17C74",
  "#4F9D69",
  "#CF5C36",
  "#000000",
  "#011C27",
  "#4F9D69",
  "#CF5C36",
  "#000000",
  "#011C27"
]

const Students = () => {
  const api = new Api();
  const toast = new Toastify();

  // states-------------
  const [add, setAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDlt, setOpenDlt] = useState(false);
  const [openPlan, setOpenPlan] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState("")
  const [studentToUpdate, setStudentToUpdate] = useState("");
  const [plans, setPlans] = useState([]);
  const [statuss, setStatus] = useState("");
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(50);
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0);
  const [studentToEdit, setStudentToEdit] = useState({
    name: '', phone: '', email: '', validity: '', status: '', type: ""
  })
  const [refresh, setRefresh] = useState(false);
  const [students, setStudents] = useState([]);

  // calculate start & end of items -------------------
  const start = (page - 1) * limit + 1;
  const end =
    totalPages === page
      ? totalItems
      : (page - 1) * limit + limit;


  // increment the page------------------------
  const increment = () => {
    if (page < totalPages) {
      setPage((prev) => prev + 1)
    }
  }

  // decrement the page------------------------
  const decrement = () => {
    if (page > 1) {
      setPage((prev) => prev - 1)
    }
  }


  // open the edit popup----------------------
  const handleOpen = (student) => {
    setOpen(true)
    setStudentToEdit(student)
  };

  // close the edit popup---------------------
  const handleClose = () => {
    setOpen(false)
    setStudentToEdit({ name: '', phone: '', email: '', validity: '', status: '' })
  };

  // fetch all student -------------------------
  const AllStudent = async () => {
    try {
      const { data, status } = await api.student({}, `?search=${search}&page=${page}&status=${statuss}&limit=${limit}`);
      if (status === 200) {
        setStudents(data.data)
        setTotalPages(data.totalPages)
        setTotalItems(data.totalItems)
      }
    } catch (err) {
      console.log(err)
    }
  }

  // run on initial render-------------------
  useEffect(() => {
    AllStudent();
  }, [refresh, page, search, statuss, limit])

  useEffect(() => {
    fetchPlan();
  }, [])

  // delete topic ------------------------
  const handleDelete = async () => {
    try {
      const { data, status } = await api.deleteStudent("", { id: studentToDelete })
      if (status === 200) {
        setRefresh(!refresh)
        setOpenDlt(false)
        setStudentToDelete("")
        toast.success("Deleted Successfully", "Your changes have been saved successfully")
      }
    } catch (err) {
      if (err.response) {
        toast.error("Error occured", err.response.data.message)
      }
    }
  }

  // fetch plans 
  const fetchPlan = async () => {
    try {
      const { data, status } = await api.getPlans();

      if (status === 200) {
        setPlans(data.data);
      }

    } catch (err) {
      console.log(err)
    }
  }

  // open delete popup-----------
  const handledltOpen = (student) => {
    setOpenDlt(true)
    setStudentToDelete(student)
  };

  // close delete popup------------ 
  const handleDltClose = () => {
    setOpenDlt(false);
    setStudentToDelete("");
  };

  const handleClosePlan = () => {
    setOpenPlan(false);
    setStudentToUpdate("");
  }

  const handlePlanOpen = (id) => {
    setOpenPlan(true);
    setStudentToUpdate(id);
  }

  return (
    <div className={classes.container}>
      <AddStudent handleClose={() => setAdd(false)} open={add} refresh={refresh} setRefresh={setRefresh} />
      <Delete handleClose={handleDltClose} open={openDlt} handleDelete={handleDelete} />
      <EditStudent handleClose={handleClose} open={open} refresh={refresh} setRefresh={setRefresh} studentToEdit={studentToEdit} />
      <UpdatePlan handleClose={handleClosePlan} plans={plans} open={openPlan} studentToUpdate={studentToUpdate} />


      <div className={classes.head}>
        <h1>students Management</h1>
        <span>
          home <RightArrow />users <RightArrow /> <span style={{ color: 'var(--Gray-900, #191B1C)' }}>all students</span>
        </span>
      </div>
      <div className={classes.subhead}>
        <span style={{ display: 'flex', gap: '15px', paddingLeft: '30px' }}>
          <Select className={classes.selectinput}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={selectInput}
            value={statuss}
            onChange={(e) => setStatus(e.target.value)}
            IconComponent={Status}
            displayEmpty
            defaultValue=''
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="ACTIVE">Active</MenuItem>
            <MenuItem value="INACTIVE">Inactive</MenuItem>
          </Select>
          <div className={styles.search}><Search /> <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search' /></div>
        </span>
        <Button sx={{ marginRight: '30px', textTransform: 'capitalize' }} onClick={() => setAdd(true)} variant="contained">Add New student</Button>
        {/* onClick={() =>navigate('/qa/quiz/add-qa')}  === onClick={() =>navigate('add-qa')}  */}
      </div>
      <div style={{ padding: '29px' }}>
        <div className={styles.header}>
          <div className={styles.fourth}>no.</div>
          <div className={styles.third}>student name</div>
          <div style={{ width: '20%' }}>email</div>
          <div className={styles.third}>phone number</div>
          <div className={styles.third}>premium validity</div>
          <div className={styles.third}>City</div>
          <div className={styles.third}>Type</div>
          <div className={styles.fourth}>status</div>
          <div style={{ width: '6%' }}>action</div>
        </div>
        {
          students.map((student, index) => (
            <div className={styles.info} key={student._id}>
              <div className={styles.fourth}>{(page - 1) * limit + index + 1}</div>
              <div className={styles.third}>
                {
                  student?.profileImg
                    ? <Avatar alt={student.name} src={student.profileImg} />
                    : <Avatar sx={{ bgcolor: colors[index] }}> {student.name.split("")[0]} </Avatar>
                }
                {student.name}
              </div>
              <div style={{ width: '20%', textTransform: "none" }}>{student.email}</div>
              <div className={styles.third}>{`${student.countryCode} ${student.phone}`}</div>
              <div className={styles.third}>
                {
                  student?.subscription?.status ? <>
                    {
                      daysRemaining(student.subscription?.end_at) > 0
                        ? `${daysRemaining(student.subscription?.end_at)} ${daysRemaining(student.subscription?.end_at) > 1 ? "Days" : "Day"}`
                        : 'expired'
                    }
                  </>
                    : <>
                      NA
                    </>
                }
              </div>
              <div className={styles.third}>
                {
                  student.city ? student.city : "NA"
                }
              </div>
              <div className={styles.third}>
                {
                  student.type ? student.type : "NA"
                }
              </div>
              <div className={styles.fourth}>
                {
                  student.status === 'ACTIVE'
                    ? <span className={styles.active}>
                      <Active /> Active
                    </span>
                    : <span className={styles.inactive}>
                      <Inactive /> Inactive
                    </span>
                }
              </div>
              <div style={{ width: '6%' }}>
                <Dropdown>
                  <MenuButton style={{ border: 'none', padding: '0' }}><Action /></MenuButton>
                  <Menu>
                    <MenuAction onClick={() => handleOpen(student)}>Edit</MenuAction>
                    <MenuAction onClick={() => handledltOpen(student._id)}>Delete</MenuAction>
                    <MenuAction onClick={() => handlePlanOpen(student._id)}>Update Plan</MenuAction>
                  </Menu>
                </Dropdown>
              </div>
            </div>
          ))
        }
        <div className={styles.pagination}>
          <div>
            Rows per page:
            <Dropdown>
              <MenuButton style={{ border: 'none', padding: '0' }}>
                <div>
                  {limit}
                </div>
              </MenuButton>
              <Menu>
                <MenuAction onClick={() => setLimit(15)} >15</MenuAction>
                <MenuAction onClick={() => setLimit(25)} >25</MenuAction>
                <MenuAction onClick={() => setLimit(50)} >50</MenuAction>
                <MenuAction onClick={() => setLimit(75)} >75</MenuAction>
                <MenuAction onClick={() => setLimit(100)} >100</MenuAction>
              </Menu>
            </Dropdown>
          </div>
          <div>Showing {start} to {end} of {totalItems} entries</div>
          <div>
            <Right handleClick={decrement} />
            {/* <p>01</p> */}
            <p className={styles.onPage}>{page}</p>
            <Left handleClick={increment} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Students
