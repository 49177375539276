import React, { useEffect, useState } from 'react'
import classes from '../quiz/quiz.module.css'
import styles from '../subjects/subject.module.css'
import { Left, Right, RightArrow, } from '../../svg'
import { MenuButton, Dropdown, Menu, MenuItem as MenuAction } from '@mui/joy'
import Api from '../../helper/api';
import Toastify from '../../helper/Toastify';

const Subscribers = () => {
    const api = new Api();
    const toast = new Toastify();

    // states-------------
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50);
    const [totalPages, setTotalPages] = useState(1)
    const [totalItems, setTotalItems] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const [students, setStudents] = useState([]);

    // calculate start & end of items -------------------
    const start = (page - 1) * limit + 1;
    const end =
        totalPages === page
            ? totalItems
            : (page - 1) * limit + limit;


    // increment the page------------------------
    const increment = () => {
        if (page < totalPages) {
            setPage((prev) => prev + 1)
        }
    }

    // decrement the page------------------------
    const decrement = () => {
        if (page > 1) {
            setPage((prev) => prev - 1)
        }
    }

    // fetch all student -------------------------
    const AllStudent = async () => {
        try {
            const { data, status } = await api.getAllSubscribers({}, `?page=${page}&limit=${limit}`);
            if (status === 200) {
                setStudents(data.data)
                setTotalPages(data.totalPages)
                setTotalItems(data.totalItems)
            }
        } catch (err) {
            console.log(err)
        }
    }

    // run on initial render-------------------
    useEffect(() => {
        AllStudent();
    }, [refresh, page, limit])

    return (
        <div className={classes.container}>

            <div className={classes.head}>
                <h1>Subscribers</h1>
                <span>
                    home <RightArrow />users <RightArrow /> <span style={{ color: 'var(--Gray-900, #191B1C)' }}>all subscribers</span>
                </span>
            </div>

            <div style={{ padding: '29px' }}>
                <div className={styles.header}>
                    <div className={styles.fourth}>no.</div>
                    <div style={{ width: '20%' }}>email</div>
                </div>
                {
                    students.map((student, index) => (
                        <div className={styles.info} key={student._id}>
                            <div className={styles.fourth}>{(page - 1) * 10 + index + 1}</div>
                            <div style={{ width: '20%', textTransform: "none" }}>{student.email}</div>
                        </div>
                    ))
                }
                <div className={styles.pagination}>
                    <div>
                        Rows per page:
                        <Dropdown>
                            <MenuButton style={{ border: 'none', padding: '0' }}>
                                <div>
                                    {limit}
                                </div>
                            </MenuButton>
                            <Menu>
                                <MenuAction onClick={() => setLimit(15)} >15</MenuAction>
                                <MenuAction onClick={() => setLimit(25)} >25</MenuAction>
                                <MenuAction onClick={() => setLimit(50)} >50</MenuAction>
                                <MenuAction onClick={() => setLimit(75)} >75</MenuAction>
                                <MenuAction onClick={() => setLimit(100)} >100</MenuAction>
                            </Menu>
                        </Dropdown>
                    </div>
                    <div>Showing {start} to {end} of {totalItems} entries</div>
                    <div>
                        <Right handleClick={decrement} />
                        {/* <p>01</p> */}
                        <p className={styles.onPage}>{page}</p>
                        <Left handleClick={increment} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Subscribers
