import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import styles from '../subjects/subject.module.css'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { custom, formselect, save } from '../MaterialUI';
import { Exit, Status } from '../../svg';

import Api from '../../helper/api';
import * as yup from "yup";
import Toastify from '../../helper/Toastify';
import { useFormik } from 'formik';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  border: "none",
  padding: "27px 22px",
  height: "fit-content",
  display: "block",
  width: '380px',
  "&:focus": {
    outline: "none",
  },
};

const EditLevel = ({ handleClose, open, levelToEdit, refresh, setRefresh }) => {
  const toast = new Toastify();
  const api = new Api();

  // schema --------------------
  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    status: yup.string().required("Status is required"),
    code: yup.string().required("Code is required")
  })

  // formik to handle forms data --------------------
  const {
    errors,
    values,
    handleChange,
    touched,
    handleBlur,
    setValues,
    handleSubmit,
    resetForm
  } = useFormik({
    initialValues: {
      name: "",
      status: '',
      code: ''
    },
    validationSchema: schema,
    onSubmit: () => {
      updateLevel();
    }
  })

  // update Level -----------------------
  const updateLevel = async () => {
    try {
      const { data, status } = await api.updateLevel(values, {
        id: levelToEdit?._id
      })
      if (status === 200) {
        setRefresh(!refresh)
        resetForm();
        handleClose()
        toast.success("Updated Successfully", "Your changes have been updated successfully")
      }
    } catch (err) {
      if (err.response) {
        toast.error("Error occured", err.response.data.message)
      }
    }
  }

  useEffect(() => {
    setValues(levelToEdit)
  }, [levelToEdit, open])
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.editHead}>
          <h2 className={styles.update}>Edit Level</h2>
          <Exit />
        </div>
        <form className={styles.form}>
          <label>Level Name</label>
          <br />
          <div className={styles.input}>
            <input type="text" placeholder='Enter' name='name' value={values.name} onChange={handleChange} onBlur={handleBlur} />
          </div>
          {
            errors.name && touched.name && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-14px" }}>{errors.name}</p>
          }

          <label>Code</label>
          <br />
          <div className={styles.input}>
            <input type="text" placeholder='Enter' name='code' value={values.code} onChange={handleChange} onBlur={handleBlur} />
          </div>
          {
            errors.code && touched.code && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-14px" }}>{errors.code}</p>
          }

          <label>Status*</label>
          <br />
          <Select className={styles.formselect}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={formselect}
            onChange={handleChange}
            onBlur={handleBlur}
            name='status'
            value={values.status}
            IconComponent={Status}
            displayEmpty
            defaultValue=''
          >
            <MenuItem value="">status</MenuItem>
            <MenuItem value="ACTIVE">Active</MenuItem>
            <MenuItem value="INACTIVE">Inactive</MenuItem>
          </Select>
          {
            errors.status && touched.status && <p style={{ color: "red", fontSize: "12px", position: "relative", height: 0, top: "-5px" }}>{errors.status}</p>
          }
          <div className={styles.buttons}>
            <Button sx={custom} disableElevation onClick={handleClose} variant="contained">Cancel</Button>
            <Button onClick={handleSubmit} sx={save} disableElevation variant="contained">Update</Button>
          </div>
        </form>
      </Box>
    </Modal>
  )
}

export default EditLevel
