import React, { useEffect, useState } from 'react'
import styles from '../quiz/quiz.module.css'
import { Action, Next, RightArrow, Search, Status } from '../../svg'
import { Button, MenuItem, Select } from '@mui/material'
import { selectInput } from '../MaterialUI'
import { useNavigate } from 'react-router-dom'
import { MenuButton, Dropdown, Menu, MenuItem as MenuAction } from '@mui/joy'
import Api from '../../helper/api'
import Toastify from '../../helper/Toastify'
import Delete from '../../shared/Delete/Delete'


const AllTest = () => {
  const navigate = useNavigate();
  const api = new Api();
  const toast = new Toastify();


  const [allTest, setAllTest] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [testToDelete, setTestToDelete] = useState({
    open: false,
    id: ""
  })
  const [subject, setSubject] = useState(null);
  const [selectedChapter, setChapter] = useState(null);
  const [statuss, setStatus] = useState("");
  const [search, setSearch] = useState('')

  useEffect(() => {
    fetchAllTest();
  }, [statuss, search, refresh]);

  const fetchAllTest = async () => {
    try {
      const { data, status } = await api.getAllTest({}, `?search=${search}&status=${statuss}`);

      if (status === 200) {
        setAllTest(data.data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const chapter = allTest.find((test) => test._id._id === subject)?.chapter
  const levels = chapter?.find((chapter) => chapter.chapter._id === selectedChapter)?.level

  const handleDelete = async () => {
    try {
      const { data, status } = await api.deleteTest("", { id: testToDelete.id })

      if (status === 200) {
        handleClose();
        toast.success("Delete successfully");
        setRefresh(!refresh)
      }
    } catch (err) {
      toast.error(err.response.data.message || "Error deleting quiz")
    }
  }

  const handleClose = () => {
    setTestToDelete({
      open: false,
      id: ""
    })
  }
  return (
    <div className={styles.container}>
      <Delete handleClose={handleClose} open={testToDelete.open} handleDelete={handleDelete} />

      <div className={styles.head}>
        <h1>tests Management</h1>
        <span>
          home <RightArrow /> tests Management <RightArrow /> <span style={{ color: 'var(--Gray-900, #191B1C)' }}>all tests</span>
        </span>
      </div>
      <div className={styles.subhead}>
        <span style={{ display: 'flex', gap: '15px', paddingLeft: '30px' }}>
          <Select className={styles.selectinput}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={selectInput}
            value={statuss}
            onChange={(e) => setStatus(e.target.value)}
            IconComponent={Status}
            displayEmpty
            defaultValue=''
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="ACTIVE">Active</MenuItem>
            <MenuItem value="INACTIVE">Inactive</MenuItem>
          </Select>
          {/* <div className={styles.search}><Search /> <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search' /></div> */}
        </span>
        <Button sx={{ marginRight: '30px', textTransform: 'capitalize' }} onClick={() => navigate('/alltest/all-test/add-test')} variant="contained">Add New test</Button>
      </div>

      <div className={styles.content}>
        <div className={styles.subcontent}>
          <h5>subjects</h5>
          <div className={styles.list}>
            {
              allTest.map((test) => (
                <div className={styles.name}>
                  <span>{test?._id?.name} <span style={{ marginLeft: '18px' }} className={styles.num}>{test?.chapter?.length}</span> </span>
                  <Next handleClick={() => setSubject(test._id._id)} />
                </div>
              ))
            }
          </div>
        </div>

        <div className={styles.subcontent}>
          <h5>chapters</h5>
          <div className={styles.list}>
            {
              chapter?.map((chapter) => (
                <div className={styles.name}>
                  <span>{chapter.chapter.name} <span className={styles.num}>{chapter.level.length}</span> </span>
                  <Next handleClick={() => setChapter(chapter.chapter._id)} />
                </div>
              ))
            }
          </div>
        </div>

        <div className={styles.subcontent}>
          <h5>levels</h5>
          <div className={styles.list}>
            {
              levels?.map((level) => (
                <div className={styles.name}>
                  <span>{level.level.name}
                    {
                      level.status === "ACTIVE"
                        ? <span className={styles.green}>Active</span>
                        : <span className={styles.red}>Inactive</span>
                    }
                  </span>
                  <Dropdown>
                    <MenuButton style={{ border: 'none', padding: '0' }}><Action /></MenuButton>
                    <Menu>
                      <MenuAction onClick={() => navigate(`/alltest/all-test/view-test/${level.testId}`)}>View</MenuAction>
                      <MenuAction onClick={() => navigate(`/alltest/all-test/edit-test/${level.testId}`)}>Edit</MenuAction>
                      <MenuAction onClick={() => setTestToDelete({ open: true, id: level.testId })}>Delete</MenuAction>
                    </Menu>
                  </Dropdown>
                </div>
              ))
            }
            {/* <div className={styles.name}>
              <span>expert</span>
              <Dropdown>
                <MenuButton style={{ border: 'none', padding: '0' }}><Action /></MenuButton>
                <Menu>
                  <MenuItem>View</MenuItem>
                  <MenuItem>Edit</MenuItem>
                  <MenuItem>Delete</MenuItem>
                </Menu>
              </Dropdown>
            </div> */}
          </div>
        </div>
      </div>
    </div >
  )
}

export default AllTest
